export abstract class SearchEvent {
  protected readonly searchTerm: string;
  constructor(searchTerm: string) {
    this.searchTerm = searchTerm;
  }
  getSearchTerm(): string {
    return this.searchTerm;
  }
  abstract asSearchTrace(): SearchTrace;
}
export interface SearchTrace {
  event: string;
  searchAction: string;
  searchQuery: string;
  searchLink?: string;
}
export class Success extends SearchEvent {
  asSearchTrace(): SearchTrace {
    return {
      event: 'trackSearch',
      searchAction: 'success',
      searchQuery: this.getSearchTerm()
    };
  }
}
export class Fail extends SearchEvent {
  asSearchTrace(): SearchTrace {
    return {
      event: 'trackSearch',
      searchAction: 'fail',
      searchQuery: this.getSearchTerm()
    };
  }
}
export class Found extends SearchEvent {
  private readonly resultLink: string;
  constructor(searchTerm: string, resultLink: string) {
    super(searchTerm);
    this.resultLink = resultLink;
  }
  getResultLink(): string {
    return this.resultLink;
  }
  asSearchTrace(): SearchTrace {
    return {
      event: 'trackSearch',
      searchAction: 'linkClick',
      searchQuery: this.getSearchTerm(),
      searchLink: this.getResultLink()
    };
  }
}