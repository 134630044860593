import { action, extendObservable, Lambda, makeObservable, observable, reaction } from 'mobx';
import { SchemaDefinition } from '../components/SchemaDefinition/SchemaDefinition';
import { SecurityDefs } from '../components/SecuritySchemes/SecuritySchemes';
import { OpenAPISpec } from '../types';
import { abortRequest } from '../utils';
import { loadAndBundleSpec } from '../utils/loadAndBundleSpec';
import { SCHEMA_DEFINITION_JSX_NAME, SECURITY_DEFINITIONS_COMPONENT_NAME, SECURITY_DEFINITIONS_JSX_NAME } from '../utils/openapi';
import { HistoryService } from './HistoryService';
import { MarkerService } from './MarkerService';
import { MenuStore } from './MenuStore';
import { FieldModel, MediaTypeModel, OperationModel, SpecStore } from './models';
import { RedocNormalizedOptions, RedocRawOptions } from './RedocNormalizedOptions';
import { ScrollService } from './ScrollService';
import { SearchStore } from './SearchStore';
import { UserStore } from './UserStore';
import { CodeSamplesFactory } from './CodeSamplesFactory';
import { FixedLanguageProvider } from './CodeSampleLanguageProvider';
import { GoogleTagManagerSearchEventProcessor, SearchEventProcessor } from './SearchEventProcessor';
import * as Redirects from './Redirects';
export interface StoreState {
  menu: {
    activeItemIdx: number;
  };
  spec: {
    url?: string;
    data: any;
  };
  searchIndex: any;
  options: RedocRawOptions;
}
export async function createStore(spec: object, specUrl: string | undefined, options: RedocRawOptions = {}) {
  const resolvedSpec = await loadAndBundleSpec(spec || specUrl);
  return new AppStore(resolvedSpec, specUrl, options);
}
export class AppStore {
  /**
   * deserialize store
   * **SUPER HACKY AND NOT OPTIMAL IMPLEMENTATION**
   */
  // TODO:
  static fromJS(state: StoreState): AppStore {
    const inst = new AppStore(state.spec.data, state.spec.url, state.options, false);
    inst.menu.activeItemIdx = state.menu.activeItemIdx || 0;
    inst.menu.activate(inst.menu.flatItems[inst.menu.activeItemIdx]);
    if (!inst.options.disableSearch) {
      inst.search!.load(state.searchIndex);
    }
    return inst;
  }
  menu: MenuStore;
  spec: SpecStore;
  rawOptions: RedocRawOptions;
  options: RedocNormalizedOptions;
  history: HistoryService;
  search?: SearchStore<string>;
  marker = new MarkerService();
  @observable
  user: UserStore;
  readonly codeSamplesFactory: CodeSamplesFactory;
  searchEventProcessor: SearchEventProcessor;
  private scroll: ScrollService;
  private disposer: Lambda | null = null;
  private fetchUserXhr?: XMLHttpRequest;
  private readonly FETCH_USER_PATH = '/experience/1/user';
  private readonly LOGOUT_PATH = '/login/v1/logout';
  private readonly LOGOUT_METHOD = 'POST';
  private fetchUserUrl: string;
  private sessionLogoutUrl: string;
  constructor(spec: OpenAPISpec, specUrl?: string, options: RedocRawOptions = {}, createSearchIndex: boolean = true) {
    makeObservable(this);
    extendObservable(this, {
      user: observable
    });
    this.rawOptions = options;
    this.options = new RedocNormalizedOptions(options, DEFAULT_OPTIONS);
    this.scroll = new ScrollService(this.options);

    // update position statically based on hash (in case of SSR)
    this.fetchUserUrl = this.options.apiUrl + this.FETCH_USER_PATH;
    this.sessionLogoutUrl = this.options.apiUrl + this.LOGOUT_PATH;
    this.spec = new SpecStore(spec, specUrl, this.options);
    this.searchEventProcessor = new GoogleTagManagerSearchEventProcessor();
    this.history = new HistoryService(Redirects.getRedirectApplier(), this.options.baseUrl);
    this.menu = new MenuStore(this.spec, this.scroll, this.history, this.searchEventProcessor);
    this.user = new UserStore();
    this.codeSamplesFactory = new CodeSamplesFactory(new FixedLanguageProvider());
    this.menu.updateOnHistoryWithScroll(this.history.currentId, this.scroll);
    this.options.logoUrl = this.options.theme.logo?.url;
    if (!this.options.navBarEnabled) {
      this.options.theme.navBar.height = '0px';
    }
    if (!this.options.disableSearch) {
      this.search = new SearchStore();
      if (createSearchIndex) {
        this.search.indexItems(this.menu.items);
      }
      this.disposer = reaction(() => this.menu.activeItemIdx, newValue => {
        this.updateMarkOnMenu((newValue as number));
      });
    }
  }
  onDidMount() {
    this.menu.updateOnHistory();
    this.updateMarkOnMenu(this.menu.activeItemIdx);
    this.fetchUser();
  }
  @action
  logoutUser = () => {
    abortRequest(this.fetchUserXhr);
    const request = new XMLHttpRequest();
    request.open(this.LOGOUT_METHOD, this.sessionLogoutUrl, true);
    request.withCredentials = true;
    request.onloadend = action('logoutUserOnloadend', () => {
      this.user = new UserStore();
    });
    request.send();
    this.fetchUserXhr = request;
  };
  expandFieldAndSubFields = (field: FieldModel) => {
    field.expanded = true;
    const subFields = field.schema.fields;
    const subItems = field.schema.items;
    const oneOf = field.schema.oneOf;
    if (subFields) {
      subFields.forEach(this.expandFieldAndSubFields);
    }
    if (subItems) {
      subItems.fields?.map(this.expandFieldAndSubFields);
      const subOneOf = subItems.oneOf;
      if (subOneOf) {
        subOneOf.forEach(schema => schema.fields?.map(this.expandFieldAndSubFields));
      }
    }
    if (oneOf) {
      oneOf.forEach(schema => schema.fields?.map(this.expandFieldAndSubFields));
    }
    return;
  };
  collapseFieldAndSubFields = (field: FieldModel) => {
    field.expanded = false;
    const subFields = field.schema.fields;
    const subItems = field.schema.items;
    const oneOf = field.schema.oneOf;
    if (subFields) {
      subFields.forEach(this.collapseFieldAndSubFields);
    }
    if (subItems) {
      subItems.fields?.forEach(this.collapseFieldAndSubFields);
      const subOneOf = subItems.oneOf;
      if (subOneOf) {
        subOneOf.forEach(schema => schema.fields?.forEach(this.collapseFieldAndSubFields));
      }
    }
    if (oneOf) {
      oneOf.forEach(schema => schema.fields?.forEach(this.collapseFieldAndSubFields));
    }
    return;
  };
  @action
  expandFieldsAndSamples = (responseCode?: string) => {
    const activeItem = this.menu.flatItems[this.menu.activeItemIdx];
    const activeOperation = (activeItem as OperationModel);
    if (responseCode !== undefined) {
      this.morphResponse(activeOperation, responseCode, this.expandExamplesInMediaType, this.expandFieldAndSubFields);
    } else {
      this.expandRequest(activeOperation);
    }
  };
  @action
  collapseFieldsAndSamples = (responseCode?: string) => {
    const activeItem = this.menu.flatItems[this.menu.activeItemIdx];
    const activeOperation = (activeItem as OperationModel);
    if (responseCode !== undefined) {
      this.morphResponse(activeOperation, responseCode, this.collapseExamplesInMediaType, this.collapseFieldAndSubFields);
    } else {
      this.collapseRequest(activeOperation);
    }
  };
  morphResponse(activeOperation: OperationModel, responseCode: string, exampleMorpher: (mediaType: MediaTypeModel) => void, fieldMorpher: (field: FieldModel) => void) {
    activeOperation.responses.filter(response => response.code === responseCode).forEach(response => {
      response.content?.mediaTypes.forEach(exampleMorpher);
      response.content?.active.schema?.fields?.forEach(fieldMorpher);
      response.content?.active.schema?.oneOf?.forEach(oneOf => oneOf.fields?.map(fieldMorpher));
    });
  }
  expandExamplesInMediaType(mediaType: MediaTypeModel) {
    if (mediaType.examples) {
      Object.values(mediaType.examples).forEach(exampleModel => {
        exampleModel.expanded = true;
      });
    }
  }
  collapseExamplesInMediaType(mediaType: MediaTypeModel) {
    if (mediaType.examples) {
      Object.values(mediaType.examples).forEach(exampleModel => {
        exampleModel.expanded = false;
      });
    }
  }
  expandRequest(activeOperation: OperationModel) {
    activeOperation.requestBody?.content?.mediaTypes.map(this.expandExamplesInMediaType);
    activeOperation.requestBody?.content?.active.schema?.fields?.map(this.expandFieldAndSubFields);
  }
  collapseRequest(activeOperation: OperationModel) {
    activeOperation.requestBody?.content?.mediaTypes.map(this.collapseExamplesInMediaType);
    activeOperation.requestBody?.content?.active.schema?.fields?.map(this.collapseFieldAndSubFields);
  }
  dispose() {
    this.scroll.dispose();
    this.menu.dispose();
    if (this.disposer != null) {
      this.disposer();
    }
    abortRequest(this.fetchUserXhr);
  }

  /**
   * serializes store
   * **SUPER HACKY AND NOT OPTIMAL IMPLEMENTATION**
   */
  // TODO: improve
  async toJS(): Promise<StoreState> {
    return {
      menu: {
        activeItemIdx: this.menu.activeItemIdx
      },
      spec: {
        url: this.spec.parser.specUrl,
        data: this.spec.parser.spec
      },
      searchIndex: this.search ? await this.search.toJS() : undefined,
      options: this.rawOptions
    };
  }
  private fetchUser() {
    abortRequest(this.fetchUserXhr);
    const request = new XMLHttpRequest();
    request.open('GET', this.fetchUserUrl, true);
    request.withCredentials = true;
    request.onload = () => {
      if (request.readyState === 4 && request.status === 200) {
        const response = JSON.parse(request.responseText);
        this.user = {
          endpointUrl: response.endpointUrl,
          accountHash: response.accountHash,
          apiKey: response.apiKey,
          phoneNumber: response.phoneNumber,
          userName: response.userName,
          loggedIn: true,
          allowedApiKeyView: response.allowedApiKeyView ?? true // allowedApiKeyView is not deployed on experService yet, so its need to be set defaultly to true
        };
      }
    };
    request.send();
    this.fetchUserXhr = request;
  }
  private updateMarkOnMenu(idx: number) {
    const start = Math.max(0, idx);
    const end = Math.min(this.menu.flatItems.length, start + 5);
    const elements: Element[] = [];
    for (let i = start; i < end; i++) {
      let elem = this.menu.getElementAt(i);
      if (!elem) {
        continue;
      }
      if (this.menu.flatItems[i].type === 'page') {
        elem = elem.parentElement!.parentElement;
      }
      if (elem) {
        elements.push(elem);
      }
    }
    this.marker.addOnly(elements);
    this.marker.mark();
  }
}
const DEFAULT_OPTIONS: RedocRawOptions = {
  allowedMdComponents: {
    [SECURITY_DEFINITIONS_COMPONENT_NAME]: {
      component: SecurityDefs,
      propsSelector: (store: AppStore) => ({
        securitySchemes: store.spec.securitySchemes
      })
    },
    [SECURITY_DEFINITIONS_JSX_NAME]: {
      component: SecurityDefs,
      propsSelector: (store: AppStore) => ({
        securitySchemes: store.spec.securitySchemes
      })
    },
    [SCHEMA_DEFINITION_JSX_NAME]: {
      component: SchemaDefinition,
      propsSelector: (store: AppStore) => ({
        parser: store.spec.parser,
        options: store.options
      })
    }
  }
};