import { observer } from 'mobx-react';
import * as React from 'react';
import { Badge, H1, H2, Link, LinkH2, MiddlePanel, Row, Section, ShareLink, ShelfIcon } from '../../common-elements';
import { CategoryMenuItem, ContentItemModel, ModuleMenuItem, OperationModel, ProductMenuItem, SectionMenuItem, SubmoduleMenuItem } from '../../services';
import { shortenHTTPVerb } from '../../utils';
import { AdvancedMarkdown } from '../Markdown/AdvancedMarkdown';
import { StyledDiv } from '../NavBar/styled.elements';
import { OperationBadge } from '../SideMenu';
import Survey from '../Survey/Survey';
const elementResolverMapper = new Map([['product', item => <ProductItem item={(item as ProductMenuItem)} key={item.id} />], ['module', item => <ModuleItem item={(item as ModuleMenuItem)} key={item.id} />], ['submodule', item => <ModuleItem item={(item as ModuleMenuItem)} key={item.id} />], ['section', item => <SectionItem item={(item as SectionMenuItem)} key={item.id} />], ['operation', item => <OperationItem operation={(item as OperationModel)} key={item.id} />]]);
function resolveElements(items: ContentItemModel[]): JSX.Element[] {
  const resolvedElements: JSX.Element[] = [];
  items.forEach(childItem => {
    const mapper = elementResolverMapper.get(childItem.type);
    if (mapper) {
      resolvedElements.push(mapper(childItem));
    }
  });
  return resolvedElements;
}
@observer
export class CategoryContent extends React.Component<{
  item: CategoryMenuItem;
}> {
  render() {
    const {
      id,
      name,
      description,
      items
    } = this.props.item;
    return <Section id={id} underlined={false}>
        <Row>
          <MiddlePanel compact={true}>
            <H1>{name}</H1>
            <AdvancedMarkdown source={description || ''} />
            {resolveElements(items)}
            <Survey />
          </MiddlePanel>
        </Row>
      </Section>;
  }
}
@observer
export class ProductContent extends React.Component<{
  item: ProductMenuItem;
}> {
  render() {
    const {
      id,
      name,
      description,
      items
    } = this.props.item;
    return <Section id={id} underlined={false}>
        <Row style={{
        paddingBottom: '50px'
      }}>
          <MiddlePanel compact={true}>
            <H1>
              <ShareLink to={id} />
              {name}
            </H1>
            <AdvancedMarkdown source={description || ''} />
            {resolveElements(items)}
            <Survey />
          </MiddlePanel>
        </Row>
      </Section>;
  }
}
@observer
export class ModuleContent extends React.Component<{
  item: ModuleMenuItem;
}> {
  render() {
    const {
      id,
      name,
      description,
      items
    } = this.props.item;
    return <Section id={id} underlined={false}>
        <Row style={{
        paddingBottom: '50px'
      }}>
          <MiddlePanel compact={true}>
            <H1>
              <ShareLink to={id} />
              {name}
            </H1>
            {description && <AdvancedMarkdown source={description} />}
            {resolveElements(items)}
            <Survey />
          </MiddlePanel>
        </Row>
      </Section>;
  }
}
@observer
class ProductItem extends React.Component<{
  item: ProductMenuItem;
}> {
  render() {
    return <>
        <StyledDiv>
          <Link to={this.props.item.id}>
            <LinkH2>
              <ShelfIcon direction={'right'} />
              {this.props.item.name}
            </LinkH2>
          </Link>
          <AdvancedMarkdown source={this.props.item.description || ''} />
        </StyledDiv>
      </>;
  }
}
@observer
export class ModuleItem extends React.Component<{
  item: ModuleMenuItem | SubmoduleMenuItem;
}> {
  render() {
    return <>
        <StyledDiv>
          <Link to={this.props.item.id}>
            <LinkH2 style={{
            paddingTop: '25px'
          }}>
              <ShelfIcon direction={'right'} />
              {this.props.item.name}
            </LinkH2>
          </Link>
          <AdvancedMarkdown source={this.props.item.description || ''} />
        </StyledDiv>
      </>;
  }
}
@observer
export class SectionItem extends React.Component<{
  item: SectionMenuItem;
}> {
  render() {
    const operationItems = this.props.item.items.map(operation => <OperationItem operation={(operation as OperationModel)} key={operation.id} />);
    return <>
        <H2 style={{
        paddingTop: '25px'
      }}>{this.props.item.name}</H2>
        {operationItems}
      </>;
  }
}
@observer
export class OperationItem extends React.Component<{
  operation: OperationModel;
}> {
  render() {
    const {
      id,
      name,
      httpVerb,
      description,
      deprecated,
      xIsEarlyAccess,
      isWebhook,
      xLandingPageDescription,
      isSunsetted
    } = this.props.operation;
    const operationWidth = 32;
    const operationMargin = 6;
    const operationContainerStyle = {
      marginTop: '40px'
    };
    const operationBadgeStyle = {
      verticalAlign: 'text-top',
      marginRight: `${operationMargin}px`
    };
    const badgeStyle = {
      verticalAlign: 'text-top',
      marginLeft: `${operationMargin}px`
    };
    const linkStyle = {
      verticalAlign: 'text-top'
    };
    const descriptionStyle = {
      marginLeft: `${operationWidth + operationMargin}px`
    };
    const webhookBadge = isWebhook && <Badge type="webhook" style={badgeStyle}>
        {' '}
        Webhook{' '}
      </Badge>;
    const deprecatedBadge = deprecated && <Badge type="deprecated" style={badgeStyle}>
        {' '}
        Deprecated{' '}
      </Badge>;
    const earlyAccessBadge = xIsEarlyAccess && <Badge type="warning" style={badgeStyle}>
        {' '}
        Early access{' '}
      </Badge>;
    return !isSunsetted && <>
          <StyledDiv style={operationContainerStyle}>
            <ShareLink to={id} />
            <OperationBadge type={httpVerb} style={operationBadgeStyle}>
              {shortenHTTPVerb(httpVerb)}
            </OperationBadge>
            <Link to={id}>
              <span style={linkStyle}>{name}</span>
            </Link>
            {webhookBadge}
            {deprecatedBadge}
            {earlyAccessBadge}
          </StyledDiv>
          <div style={descriptionStyle}>
            {xLandingPageDescription && <AdvancedMarkdown source={xLandingPageDescription} /> || description && <AdvancedMarkdown source={description} /> || null}
          </div>
        </>;
  }
}