import styled, { css, extensionsHook } from '../styled-components';
import { ShelfIcon } from './shelfs';
const headerFontSize = {
  1: '2em',
  2: '1.75em',
  3: '1.4em'
};
export const headerCommonMixin = level => css`
  font-family: ${({
  theme
}) => theme.typography.headings.fontFamily};
  font-weight: ${({
  theme
}) => theme.typography.headings.fontWeight};
  font-size: ${headerFontSize[level]};
  line-height: ${({
  theme
}) => theme.typography.headings.lineHeight};
`;
export const H1 = styled.h1`
  ${headerCommonMixin(1)};
  margin-top: 35px;
  color: ${({
  theme
}) => theme.menu.textColor};
  ${extensionsHook('H1')};
`;
export const H2 = styled.h2`
  ${headerCommonMixin(2)};
  color: ${({
  theme
}) => theme.menu.textColor};

  ${extensionsHook('H2')};
`;
export const LinkH2 = styled.h2`
  ${headerCommonMixin(2)};
  ${extensionsHook('H2')};

  display: flex;
  align-items: center;
  margin: 40px 0 16px;
  color: ${({
  theme
}) => theme.colors.primary.main};

  ${ShelfIcon} {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;
export const H3 = styled.h2`
  ${headerCommonMixin(3)};
  color: ${({
  theme
}) => theme.menu.textColor};

  ${extensionsHook('H3')};
`;
export const RightPanelHeader = styled.h3`
  color: ${({
  theme
}) => theme.rightPanel.textColor};

  ${extensionsHook('RightPanelHeader')};
`;
export const UnderlinedHeader = styled.h5`
  border-bottom: 1px solid rgba(38, 50, 56, 0.3);
  margin: 1em 0 1em 0;
  color: rgba(38, 50, 56, 0.5);
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.929em;
  ${extensionsHook('UnderlinedHeader')};
`;
export const ReqResSchemaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(38, 50, 56, 0.3);

  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ReqResSchemaTypeChooser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: inherit;
`;