import { __ } from 'ib-i18n';
import * as React from 'react';
import { Wrapper, StyledApplier } from './styles';
interface ApplierProps {
  text: string;
  action?: () => void;
}
const SpanApplier = (props: ApplierProps) => {
  return <StyledApplier>
      <span onClick={props.action}>{props.text}</span>
    </StyledApplier>;
};
interface ExpandCollapseProps {
  onExpand?: () => void;
  onCollapse?: () => void;
  expanded?: boolean;
}
export const ExpandCollapse = (props: ExpandCollapseProps) => {
  const {
    onExpand,
    onCollapse,
    expanded
  } = props;
  const toggleExpandedState = () => {
    if (onExpand !== undefined && onCollapse !== undefined) {
      if (expanded) {
        onCollapse();
      } else {
        onExpand();
      }
    }
  };
  return <Wrapper>
      <SpanApplier text={!expanded ? `${__('expand all')} +` : `${__('collapse all')} -`} action={toggleExpandedState} />
    </Wrapper>;
};