import * as React from 'react';
import styled from '../../styled-components';
import { MenuItemLabel } from '../SideMenu/styled.elements';
export const SearchWrap = styled.div`
  position: relative;
  padding: 5px 0px;
  margin: 0px 24px;
  margin-top: 28px;
`;
export const SearchInput = styled.input.attrs(() => ({
  className: 'search-input'
}))`
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 10px 10px 45px;
  font-family: ${({
  theme
}) => theme.typography.fontFamily};
  font-weight: bold;
  font-size: 13px;
  color: ${props => props.theme.menu.textColor};
  outline: none;
  border-radius: 8px;
  border: 1px solid #d0dcf2;
  background: white;
`;
export const SearchIcon = styled((props: {
  className?: string;
}) => <svg className={props.className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 11.5004C14.5 13.9856 12.4853 16.0004 10 16.0004C7.51472 16.0004 5.5 13.9856 5.5 11.5004C5.5 9.01508 7.51472 7.00037 10 7.00037C12.4853 7.00037 14.5 9.01508 14.5 11.5004ZM14.5962 16.0966C14.4009 15.9013 14.0856 15.9031 13.8749 16.0815C12.8295 16.9666 11.4771 17.5004 10 17.5004C6.68629 17.5004 4 14.8141 4 11.5004C4 8.18666 6.68629 5.50037 10 5.50037C13.3137 5.50037 16 8.18666 16 11.5004C16 12.4747 15.7677 13.3949 15.3556 14.2084C15.2524 14.4119 15.2821 14.6611 15.4434 14.8225L19.3738 18.7528C19.569 18.9481 19.569 19.2647 19.3738 19.4599L19.0202 19.8135C18.825 20.0087 18.5084 20.0087 18.3131 19.8135L14.5962 16.0966Z" fill="#677796" />
  </svg>).attrs({
  className: 'search-icon'
})`
  position: absolute;
  top: 12px;
  left: 12px;

  path {
    fill: ${props => props.theme.menu.textColor};
  }
`;
export const SearchResultsBox = styled.div`
  padding: ${props => props.theme.spacing.unit}px 0;
  line-height: 1.4;
  font-size: 0.9em;

  a {
    text-decoration: None;
    color: inherit;
  }

  ${MenuItemLabel} {
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
      background-color: ${props => props.theme.menu.backgroundColor};
    }

    > svg {
      display: none;
    }

    &.active {
      background-color: #e1e1e1;
    }
  }
`;
export const ClearIcon = styled.i`
  position: absolute;
  display: inline-block;
  width: ${props => props.theme.spacing.unit * 2}px;
  text-align: center;
  right: 12px;
  line-height: 2em;
  vertical-align: middle;
  margin-right: 2px;
  cursor: pointer;
  font-style: normal;
  color: #666;
  top: 7px;
`;