import { bind, debounce } from 'decko';
import { EventEmitter } from 'eventemitter3';
import { IS_BROWSER } from '../utils/';
import * as Redirects from './Redirects';
const EVENT = 'popstate';
export class HistoryService {
  private _emiter;
  private readonly redirectApplier;
  private baseUrl;
  constructor(redirectApplier: Redirects.RedirectApplier, baseUrl: string) {
    this._emiter = new EventEmitter();
    this.redirectApplier = redirectApplier;
    this.baseUrl = baseUrl;
    this.bind();
  }
  get currentId(): string {
    const hash = window.location.hash;
    if (hash && hash !== '') {
      return IS_BROWSER ? this.redirectId(decodeURIComponent(hash.substring(1))) : '';
    }
    let location = window.location.pathname;
    if (!location || location === '/') {
      location = hash.substring(1);
    }
    if (location.startsWith(this.baseUrl)) {
      location = location.substring(this.baseUrl.length);
    }
    return IS_BROWSER ? this.redirectId(decodeURIComponent(location.substring(1))) : '';
  }
  linkForId(id: string) {
    if (!id) {
      if (this.baseUrl === '') {
        return '/';
      }
      return this.baseUrl;
    }
    return this.baseUrl + '/' + id;
  }
  subscribe(cb): () => void {
    const emmiter = this._emiter.addListener(EVENT, cb);
    return () => emmiter.removeListener(EVENT, cb);
  }
  emit = () => {
    this._emiter.emit(EVENT, this.currentId);
  };
  bind() {
    if (IS_BROWSER) {
      window.addEventListener('popstate', this.emit, false);
    }
  }
  dispose() {
    if (IS_BROWSER) {
      window.removeEventListener('popstate', this.emit);
    }
  }
  @bind
  @debounce
  replace(id: string | null, rewriteHistory: boolean = false) {
    if (!IS_BROWSER) {
      return;
    }
    if (id == null || id === this.currentId) {
      return;
    }
    if (rewriteHistory) {
      this.rewriteHistory(id);
      return;
    }
    window.history.pushState(null, '', this.linkForId(id));
    this.emit();
  }
  private redirectId(id: string) {
    const redirectId = this.redirectApplier.redirect(id);
    if (redirectId) {
      this.rewriteHistory(redirectId);
      return redirectId;
    }
    return id;
  }
  private rewriteHistory(id: string) {
    window.history.replaceState(null, '', this.linkForId(id));
  }
}