import { __ } from 'ib-i18n';
import * as React from 'react';
import { Markdown } from '..';
import { Accordion } from './Accordion';
export interface AdditionalInfoProps {
  content: {
    title?: string;
    markdown: string;
  };
}
export class AdditionalInfo extends React.Component<AdditionalInfoProps> {
  render() {
    const {
      content
    } = this.props;
    return <Accordion title={content.title || __('Additional info')}>
        <Markdown source={content.markdown} />
      </Accordion>;
  }
}