import * as React from 'react';
import { __ } from 'ib-i18n';
import { ThrottlingLearnMore, TimeLimitWrapper } from './styled.elements';
import { OpenAPIXThrottlingInformation } from '../../types';
interface TimeRateLimitProps {
  timeImplementation: OpenAPIXThrottlingInformation;
}
const TimeUnitToString = {
  s: __('second'),
  m: __('minute'),
  h: __('hour'),
  d: __('day')
};
export const TimeRateLimit = ({
  timeImplementation
}: TimeRateLimitProps): JSX.Element => {
  const timeLimitStringify = () => {
    if (!timeImplementation.timeUnit) {
      return '';
    }
    if (timeImplementation.numberOfTimeUnits === 1 || !timeImplementation.numberOfTimeUnits) {
      return timeImplementation.numberOfRequests + ' requests / ' + TimeUnitToString[timeImplementation.timeUnit];
    }
    return timeImplementation.numberOfRequests + ' requests / ' + timeImplementation.numberOfTimeUnits + ' ' + TimeUnitToString[timeImplementation.timeUnit];
  };
  return <TimeLimitWrapper>
      <div>
        {timeLimitStringify()}
        {` ${__('(per account)')}`}
      </div>
      <div>
        <ThrottlingLearnMore href="https://www.infobip.com/docs/essentials/integration-best-practices#throttling-handling-errors">
          {__('Learn more')}
        </ThrottlingLearnMore>
      </div>
    </TimeLimitWrapper>;
};