import classnames from 'classnames';
import { deprecatedCss, ShelfIcon, HomeIcon } from '../../common-elements';
import styled, { media } from '../../styled-components';
import { MenuItemType } from '../../services';
const Badge = styled.span`
  min-width: 32px;
  display: inline-block;
  align-self: flex-start;
  height: ${props => props.theme.typography.code.fontSize};
  line-height: ${props => props.theme.typography.code.fontSize};
  background-color: #333;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: 6px 4px;
  font-size: 7px;
  font-family: Verdana; // web-safe
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: 5px;
`;
export const OperationBadge = styled((Badge as any)).attrs((props: {
  type: string;
}) => ({
  className: `operation-type ${props.type}`
}))<{
  type: string;
}>`
  &.get {
    background-color: ${props => props.theme.colors.http.get};
  }

  &.post {
    background-color: ${props => props.theme.colors.http.post};
  }

  &.put {
    background-color: ${props => props.theme.colors.http.put};
  }

  &.options {
    background-color: ${props => props.theme.colors.http.options};
  }

  &.patch {
    background-color: ${props => props.theme.colors.http.patch};
  }

  &.delete {
    background-color: ${props => props.theme.colors.http.delete};
  }

  &.basic {
    background-color: ${props => props.theme.colors.http.basic};
  }

  &.link {
    background-color: ${props => props.theme.colors.http.link};
  }

  &.head {
    background-color: ${props => props.theme.colors.http.head};
  }
`;
export const TooltipWebhook = styled.span`
  position: absolute;
  top: 50%;
  left: calc(100% + 6px);
  display: block;
  min-width: 45px;
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 8px;
  background-color: #55524d;
  color: #f8f9fa;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-50%);
  transition: opacity 200ms ease-in-out;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-right: 5px solid #55524d;
    border-bottom: 3px solid transparent;
    margin-left: 8px;
    transform: translate(0, -50%);
  }
`;
export const WebhookBadge = styled((Badge as any))`
  position: relative;
  min-width: 22px;
  margin-top: -2px;
  font-weight: 700;
  background-color: ${props => props.theme.colors.webhook.main};

  &:hover ${TooltipWebhook} {
    opacity: 1;
  }
`;
export const MenuItemUl = styled.ul<{
  expanded: boolean;
}>`
  margin: 0;
  padding: 0;

  &[role='navigation'] {
    padding-bottom: 20px;

    ${media.lessThan('small')`
      padding-bottom: 110px;
    `};
  }

  & & {
    font-size: 0.929em;
  }

  a {
    text-decoration: None;
    color: inherit;
  }

  ${props => props.expanded ? 'display: block;' : 'display: none;'};
`;
export const MenuItemLi = styled.li<{
  depth: number;
}>`
  list-style: none inside none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
`;
export interface MenuItemLabelType {
  depth: number;
  active: boolean;
  deprecated?: boolean;
  type?: string;
  cssDisplay?: string;
  expanded?: boolean;
  childrenType?: MenuItemType;
  hasActiveChild?: boolean;
  latestBadge?: boolean;
  versionNumber?: number;
}
export const MenuItemLabel = styled.label.attrs((props: MenuItemLabelType) => ({
  role: 'menuitem',
  className: classnames('-depth' + props.depth, {
    active: props.active
  })
}))<MenuItemLabelType>`
  display: ${props => props.cssDisplay ? props.cssDisplay : 'flex'};
  align-items: center;
  justify-content: space-between;
  padding: 11px ${props => props.theme.spacing.unit * 3}px;
  border-radius: 3px;
  margin: 0 8px 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  will-change: background-color;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;

  ${props => props.deprecated && deprecatedCss || ''};

  ${({
  theme,
  type
}) => type === 'page' && `
    justify-content: flex-start;
    padding-left: 10px;
    margin-top: 30px;
    background-position: left center;
    font-size: 17px;
    font-weight: 600;
    will-change: background-color;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &.active {
      background-color: ${theme.menu.backgroundColorActive};
    }

    span {
      margin-left: 5px;
    }
  `}

  ${({
  theme,
  type
}) => type === 'category' && `
    padding-right: 25px;
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    will-change: background-color;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &.active {
      background-color: ${theme.menu.backgroundColorActive};
    }
  `}

  ${({
  theme,
  type
}) => type === 'product' && `
    position: relative;
    padding: 5px 15px 5px 20px;
    margin: 0 15px 0 10px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      background-color: ${theme.menu.backgroundColorActive};
    }
  `}

  ${({
  theme,
  type,
  hasActiveChild
}) => type === 'product' && hasActiveChild && `
    position: static;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0 10px 5px 0;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    background-color: transparent;

    &:hover {
      background-color: ${theme.menu.backgroundColorActive};
    }
  `}

  ${({
  theme,
  type,
  active
}) => type === 'product' && active && `
    position: static;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0 10px 5px 0;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    background-color: ${theme.menu.backgroundColorActive};
  `}

  ${({
  theme,
  type,
  childrenType
}) => type === 'product' && (childrenType === 'section' || childrenType === 'operation') && `
    margin-bottom: 0;

    ~ ul {
      margin-right: 10px;
      background-color: ${theme.menu.backgroundColorActive};
    }

    ~ ul > li:first-of-type {
      margin-top: 24px;
    }

    ~ ul > li {
      padding-top: 0;
    }

    ~ ul > li:first-of-type > label {
      border-top: 0;
    }
  `}

  ${({
  theme,
  type
}) => type === 'module' && `
    position: relative;
    justify-content: flex-start;
    padding: 5px 15px 5px 10px;
    margin-top: 15px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      background-color: ${theme.menu.backgroundColorActive};
    }
  `}

  ${({
  type,
  childrenType
}) => type === 'module' && childrenType === 'submodule' && `
      padding-left: 12px;
    `}

  ${({
  type,
  childrenType
}) => type === 'module' && (childrenType === 'section' || childrenType === 'operation') && `
      padding-left: 0;
    `}

  ${({
  theme,
  type,
  active
}) => type === 'module' && active && `
      background-color: ${theme.menu.backgroundColorActive};
  `}

  ${({
  theme,
  type,
  childrenType
}) => type === 'module' && childrenType === 'section' && `
      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 10px;
        margin-bottom: 0;
        background-color: ${theme.menu.backgroundColorActive};

        ~ ul {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          margin-right: 10px;
          background-color: ${theme.menu.backgroundColorActive};
        }

        ~ ul > li:first-of-type {
          margin-top: 0;
        }

        ~ ul > li {
          padding-top: 0;
        }

        ~ ul > li:first-of-type > label {
          border-top: 0;
        }
      }
    `};

    ${({
  type,
  childrenType
}) => type === 'module' && (childrenType === 'section' || childrenType === 'operation') && `
      > svg {
        width: 1.25em!important;
        height: 1.25em!important;
      }
    `}

  ${({
  theme,
  type,
  active,
  childrenType,
  hasActiveChild
}) => type === 'module' && (active || hasActiveChild) && (childrenType === 'section' || childrenType === 'operation') && `
      margin-bottom: 0;
      color: ${theme.menu.textColor};
      background-color: ${theme.menu.backgroundColorActive};

      &:hover {
        background-color: ${theme.menu.backgroundColorActive};
      }

      ~ ul {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        margin-right: 10px;
        background-color: ${theme.menu.backgroundColorActive};
      }
    `}

  ${({
  type,
  active,
  childrenType,
  hasActiveChild
}) => type === 'module' && (active || hasActiveChild) && childrenType === 'operation' && `
    ~ ul {
      padding-top: 15px;
    }

  `}

  ${({
  type,
  active,
  childrenType,
  hasActiveChild
}) => type === 'module' && (active || hasActiveChild) && childrenType === 'section' && `
    ~ ul > li:first-of-type {
      margin-top: 0;
    }

    ~ ul > li {
      padding-top: 0;
    }

    ~ ul > li:first-of-type > label {
      border-top: 0;
    }
  `}

  ${({
  theme,
  type
}) => type === 'submodule' && `
    justify-content: flex-start;
    padding: 5px 15px 5px 16px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    will-change: background-color;
    transition: background-color 150ms ease-in-out;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:hover {
      background-color: ${theme.menu.backgroundColorActive};
    }

    &.active {
      background-color: ${theme.menu.backgroundColorActive};
      border: 1px solid #d0dcf2;
      border-bottom: 0px;

      &:hover {
        background-color: ${theme.menu.backgroundColorActive};
      }
    }
  `};

  ${({
  theme,
  type,
  active,
  hasActiveChild
}) => type === 'submodule' && (active || hasActiveChild) && `
    background-color: ${theme.menu.backgroundColorActive};
    border: 1px solid #d0dcf2;
    border-bottom: 0px;

    &:hover {
      background-color: ${theme.menu.backgroundColorActive};
    }
  }
`};

  ${({
  theme,
  type,
  active,
  hasActiveChild
}) => type === 'submodule' && (active || hasActiveChild) && `
    background-color: ${theme.menu.backgroundColorActive};
    color: ${theme.menu.textColor};

    ~ ul {
      padding-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: ${theme.menu.backgroundColorActive};
      border: 1px solid #d0dcf2;
      border-top: 0px;
      padding-bottom: 10px;

      > li:first-of-type > label[type='section'] {
        padding-top: 0;
        border-top: 0;
      }
    }
  `};

  ${({
  type
}) => type === 'section' && `
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 23px;
    border-top: 1px solid rgba(121, 122, 124, 0.35);
    margin-right: 0;
    color: #174061;
    font-size: 15px;
    font-weight: 700;
    cursor: default;
  `};

  ${({
  theme,
  type
}) => type === 'operation' && `
    justify-content: flex-start;
    margin-left: 10px;

    &:hover {
      color: ${theme.menu.linkColorActive};
      background-color: ${theme.menu.linkBGColorActive};
    }

    &.active {
      margin-bottom: 0;
      background-color: ${theme.menu.linkBGColorActive};
      color: ${theme.menu.linkColorActive};
      font-weight: 600;
    }
  `};

${ShelfIcon} {
  height: ${({
  theme
}) => theme.menu.arrow.size};
  width: ${({
  theme
}) => theme.menu.arrow.size};
  polygon {
    fill: ${({
  theme
}) => theme.menu.arrow.color};
  }
}

&: hover {
  ${HomeIcon} {
    path: last - of - type {
      fill: ${({
  theme
}) => theme.colors.primary.main};
    }
  }
}
`;
export const MenuItemTitle = styled.span<{
  width?: string;
  type?: string;
}>`
  display: inline-block;
  width: ${props => props.width ? props.width : 'auto'};
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;
export const MenuItemDescription = styled.span`
  display: block;
  font-size: 0.8em;
`;
export const AdditionalVersioningInfo = styled.div`
  font-size: 12px;
  font-style: italic;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  gap: 4px;
`;
export const AdditionalVersioningInfoBadgeLatest = styled.span`
  font-weight: 500;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  color: white;
  background-color: #fc6423;
  border-radius: 3px;
  padding: 2px 4px 2px 4px;
  margin-left: 4px;
  gap: 8px;
`;
export const AdditionalVersioningInfoBadge = styled.span`
  font-weight: 500;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  color: #174061;
  border: 1px solid #174061;
  border-radius: 3px;
  padding: 1px 3px 1px 3px;
  margin-left: 4px;
  gap: 8px;
`;
{
  /*
    <MenuItemLabelType>
    &:hover {
      background-color: ${props => menuItemActiveBg(props.depth, props)};
      border-left-color: ${props =>
      props.active ? props.theme.colors.primary.main : menuItemActiveBg(props.depth, props)};
    }
    */
  /*
  The attribution will be included in the footer of the
  Infobip documentation page: https://dev.infobip.com.
  This part of code is commented out only to move the Redoc
  attribution from the navigation bar to the footer.
   export const RedocAttribution = styled.div`
  ${
    ({ theme }) => `
    font-size: 0.8em;
    margin-top: ${theme.spacing.unit * 2}px;
    padding: 0 ${theme.spacing.unit * 4}px;
    text-align: left;
     opacity: 0.7;
     a,
    a:visited,
    a:hover {
      color: ${theme.menu.textColor} !important;
      border-top: 1px solid ${darken(0.1, theme.menu.backgroundColor)};
      padding: ${theme.spacing.unit}px 0;
      display: block;
    }
  `};
  `;*/
}
export const ModuleIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('https://www.infobip.com/docs/assets/img/docs-new/icons/ic-module-opener.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
`;
export const BackButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 21px ${props => props.theme.spacing.unit * 3}px 21px 0;
  margin: 0;
  color: ${props => props.theme.menu.textColor};
  font-family: ${props => props.theme.typography.headings.fontFamily};

  a {
    position: relative;
    display: inline-flex;
    align-self: flex-start;
    width: auto;
    color: ${props => props.theme.menu.textColor};
    text-decoration: none;
    text-overflow: ellipsis;

    &:visited {
      color: ${props => props.theme.menu.textColor};
    }
    &:hover {
      color: ${props => props.theme.colors.primary.main};
    }
  }

  ${ShelfIcon} {
    height: ${props => props.theme.menu.arrow.size};
    width: ${props => props.theme.menu.arrow.size};
    polygon {
      fill: ${props => props.theme.colors.primary.main};
    }
  }
`;