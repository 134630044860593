import { observer } from 'mobx-react';
import * as React from 'react';
import { ActivateType, ContentItemModel, MenuStore } from '../../services';
import { OptionsContext } from '../OptionsProvider';
import { MenuItems } from './MenuItems';
import { PerfectScrollbarWrap } from '../../common-elements';
import { BackButton } from './BackButton';
{
  /*
  The attribution will be included in the footer of the
  Infobip documentation page: https://dev.infobip.com.
  This part of code is commented out only to move the Redoc
  attribution from the navigation bar to the footer.
   import { RedocAttribution } from './styled.elements';
  */
}
@observer
export class SideMenu extends React.Component<{
  menu: MenuStore;
  className?: string;
}> {
  static contextType = OptionsContext;
  declare context: React.ContextType<typeof OptionsContext>;
  private _updateScroll?: () => void;
  render() {
    const store = this.props.menu;
    const activeItem = store.flatItems[store.activeItemIdx];
    return <PerfectScrollbarWrap updateFn={this.saveScrollUpdate} className={this.props.className} options={{
      wheelPropagation: false
    }}>
        <BackButton item={activeItem} />
        {this.prepareMenu(store.items, activeItem)}
        {/*
         The attribution will be included in the footer of the
         Infobip documentation page: https://dev.infobip.com.
         This part of code is commented out only to move the Redoc
         attribution from the navigation bar to the footer.
         <RedocAttribution>
          <a target="_blank" href="https://github.com/Redocly/redoc">
            Documentation Powered by ReDoc
          </a>
         </RedocAttribution>
         */}
      </PerfectScrollbarWrap>;
  }
  activate = (activateType: ActivateType) => {
    const {
      item
    } = activateType;
    if (item && item.active && this.context.menuToggle) {
      return item.expanded ? item.collapse() : item.expand();
    }
    this.props.menu.activateAndScroll({
      item,
      updateLocation: true
    });
    setTimeout(() => {
      if (this._updateScroll) {
        this._updateScroll();
      }
    });
  };
  private prepareMenu(storeItems: ContentItemModel[], activeItem?: ContentItemModel): JSX.Element {
    const productHierarchy = activeItem?.parentProduct;
    const itemsToRender = productHierarchy ? [productHierarchy] : storeItems;
    return <MenuItems items={itemsToRender} onActivate={this.activate} root={true} />;
  }
  private saveScrollUpdate = upd => {
    this._updateScroll = upd;
  };
}