import styled from 'styled-components';
import { css } from '../../styled-components';
const textColor = props => props.theme.menu.textColor;
const linksCss = css`
  a {
    text-decoration: none;
    color: ${textColor};

    &:visited {
      color: ${props => props.theme.typography.links.visited};
    }

    &:hover {
      color: ${props => props.theme.typography.links.hover};
    }
  }
`;
export const BreadcrumbsUl = styled.ul.withConfig({
  componentId: "sc-v77999-0"
})(["list-style-type:none;padding:0 ", "px;margin-top:40px;margin-bottom:0;opacity:0.5;font-family:", ";@media (max-width:425px){padding-right:20px;padding-left:20px;}"], props => props.theme.spacing.sectionHorizontal, props => props.theme.typography.fontFamily);
export const BreadcrumbsLi = styled.li.withConfig({
  componentId: "sc-v77999-1"
})(["display:inline-flex;color:", ";&:not(:last-of-type){margin-right:5px;&::after{content:'/';}}a{display:inline-block;padding-right:5px;&:visited{color:", " !important;}&:hover{color:", " !important;}@media (max-width:599px){font-size:10px;}}"], textColor, textColor, props => props.theme.typography.links.color);
export const StyledDiv = styled.div.withConfig({
  componentId: "sc-v77999-2"
})(["", ""], linksCss);
export const BreadCrumbsWrapper = styled.div.withConfig({
  componentId: "sc-v77999-3"
})(["margin-left:-40px;}@media (max-width:425px){margin-left:-20px;}"]);
export const BreadCrumbsDeprecatedWrapper = styled.div.withConfig({
  componentId: "sc-v77999-4"
})(["margin-left:-40px;margin-top:-24px;}@media (max-width:425px){margin-left:-20px;}"]);