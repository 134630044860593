import { autorun, IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AppStore, OperationModel } from '../../services';
import { CodeSampleModel } from '../../services/CodeSamplesFactory';
import { UserStore } from '../../services/UserStore';
import { RequestSamples } from './RequestSamples';
export interface RequestSamplesContainerProps {
  operation: OperationModel;
  store: AppStore;
}
interface RequestSamplesContainerState {
  appliesToOperation?: OperationModel;
  appliesToUser?: UserStore;
  samples: CodeSampleModel[];
}
@observer
export class RequestSamplesContainer extends React.Component<RequestSamplesContainerProps, RequestSamplesContainerState> {
  dispose: IReactionDisposer;
  state: RequestSamplesContainerState = {
    samples: []
  };
  componentDidUpdate() {
    const operationChanged = this.state.appliesToOperation !== this.props.operation;
    const userChanged = this.state.appliesToUser !== this.props.store.user;
    if (operationChanged || userChanged) {
      this.createCodeSamples();
    }
  }
  componentDidMount() {
    // Need to explicitly register this.createCodeSamples with mobx,
    // as it depends on the this.props.store.user field which is
    // not used in the render method.
    this.dispose = autorun(() => this.createCodeSamples());
  }
  componentWillUnmount() {
    this.dispose();
  }
  createCodeSamples() {
    const {
      operation: currentOperation,
      store
    } = this.props;
    const {
      user: currentUser
    } = store;
    this.setState({
      appliesToUser: currentUser,
      appliesToOperation: currentOperation,
      samples: []
    }, () => store.codeSamplesFactory.create(currentOperation, currentUser)?.then(samples => {
      this.setState(prevState => {
        const sameOperation = prevState.appliesToOperation === currentOperation;
        const sameUser = prevState.appliesToUser === currentUser;
        if (sameOperation && sameUser) {
          this.setState({
            samples
          });
        }
      });
    }));
  }
  render() {
    const {
      operation
    } = this.props;
    const {
      samples
    } = this.state;
    return <RequestSamples operation={operation} samples={samples} />;
  }
}