import * as React from 'react';
import styled from '../../styled-components';
import { __ } from 'ib-i18n';
import { SampleControls } from '../../common-elements';
import { CopyButtonWrapper } from '../../common-elements/CopyButtonWrapper';
import { PrismDiv } from '../../common-elements/PrismDiv';
import { jsonToHTML } from '../../utils/jsonToHtml';
import { jsonStyles } from './style';
import { observer } from 'mobx-react';
import { AppStore, StoreConsumer } from '../..';
export interface JsonProps {
  example: {
    value: any;
    expanded: boolean;
    responseCode?: string;
  };
  className?: string;
}
const JsonViewerWrap = styled.div`
  &:hover > ${SampleControls} {
    opacity: 1;
  }
`;
@observer
class Json extends React.PureComponent<JsonProps> {
  node: HTMLDivElement;
  render() {
    const {
      value,
      expanded
    } = this.props.example;
    return <CopyButtonWrapper data={value} expanded={expanded}>
        {this.renderInner}
      </CopyButtonWrapper>;
  }
  renderInner = ({
    renderCopyButton
  }) => {
    const {
      value,
      responseCode,
      expanded
    } = this.props.example;
    return <JsonViewerWrap>
        <StoreConsumer>
          {store => <SampleControls>
              {renderCopyButton()}
              {!this.props.example.expanded ? <span onClick={this.expand.bind(this, responseCode, store)}>
                  {` ${__('Expand all')} `}
                </span> : <span onClick={this.collapse.bind(this, responseCode, store)}>
                  {` ${__('Collapse all')} `}
                </span>}
            </SampleControls>}
        </StoreConsumer>
        <PrismDiv className={this.props.className}
      // tslint:disable-next-line
      ref={node => this.node = node!} dangerouslySetInnerHTML={{
        __html: jsonToHTML(value, expanded ? Number.MAX_SAFE_INTEGER : 0)
      }} />
      </JsonViewerWrap>;
  };
  expand = (responseCode: string, store?: AppStore) => {
    store?.expandFieldsAndSamples(responseCode);
  };
  collapse = (responseCode: string, store?: AppStore) => {
    store?.collapseFieldsAndSamples(responseCode);
  };
  clickListener = (event: MouseEvent) => {
    let collapsed;
    const target = (event.target as HTMLElement);
    if (target.className === 'collapser') {
      collapsed = target.parentElement!.getElementsByClassName('collapsible')[0];
      if (collapsed.parentElement.classList.contains('collapsed')) {
        collapsed.parentElement.classList.remove('collapsed');
      } else {
        collapsed.parentElement.classList.add('collapsed');
      }
    }
  };
  componentDidMount() {
    this.node.addEventListener('click', this.clickListener);
  }
  componentWillUnmount() {
    this.node.removeEventListener('click', this.clickListener);
  }
}
export const JsonViewer = styled(Json)`
  ${jsonStyles};
`;