import * as React from 'react';
import { __ } from 'ib-i18n';
import { Schema, SchemaProps } from './Schema';
import { ArrayClosingLabel, ArrayOpenningLabel } from '../../common-elements';
import styled from '../../styled-components';
const PaddedSchema = styled.div`
  padding-left: ${({
  theme
}) => theme.spacing.unit * 2}px;
`;
export class ArraySchema extends React.PureComponent<SchemaProps> {
  render() {
    const itemsSchema = this.props.schema.items!;
    return <div>
        <ArrayOpenningLabel>{` ${__('Array')} `}</ArrayOpenningLabel>
        <PaddedSchema>
          <Schema {...this.props} schema={itemsSchema} />
        </PaddedSchema>
        <ArrayClosingLabel />
      </div>;
  }
}