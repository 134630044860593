import { observer } from 'mobx-react';
import { Badge, DarkRightPanel, H1, MiddlePanel } from '../../common-elements';
import { ShareLink } from '../../common-elements/linkify';
import { AppStore, IMenuItem } from '../../services';
import { OperationModel as OperationType } from '../../services/models';
import { UserStore } from '../../services/UserStore';
import { AdditionalInfo } from '../AdditionalInfo/AdditionalInfo';
import { EndpointInfo, HttpVerb, ServerRelativeURL, URLTextWrapContainer } from '../Endpoint/styled.elements';
import { ExternalDocumentation } from '../ExternalDocumentation/ExternalDocumentation';
import { Extensions } from '../Fields/Extensions';
import { LoginLink } from '../LoginLink/LoginLink';
import { Markdown } from '../Markdown/Markdown';
import { Parameters } from '../Parameters/Parameters';
import { RequestSamplesContainer } from '../RequestSamples/RequestSamplesContainer';
import { ResponsesList } from '../Responses/ResponsesList';
import { ResponseSamples } from '../ResponseSamples/ResponseSamples';
import { SecurityRequirements } from '../SecurityRequirement/SecurityRequirement';
import { StoreConsumer } from '../StoreBuilder';
import Survey from '../Survey/Survey';
import { DeprecationInformationBox } from '../DeprecationInformationBox/DeprecationInformationBox';
import { DeprecationBanner } from '../DeprecationBanner/DeprecationBanner';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { BreadCrumbsDeprecatedWrapper, BreadCrumbsWrapper } from '../Breadcrumbs/styled.elements';
import { CopiedIcon, CopiedText, CopyClipboardButton, Description, OperationBoxLoginInfo, OperationRow, ToolTipContainer } from './styled.elements';
import { ThrottlingInfo } from '../ThrottlingInfo/ThrottlingInfo';
import * as React from 'react';
import { __ } from 'ib-i18n';
const DEFAULT_WEBHOOK_PATH = '/your/webhook/path';
export interface OperationProps {
  operation: OperationType;
  getItemByOperationId?: (operationId: string) => IMenuItem | undefined;
}
const OperationBreadcrumbs = ({
  operation
}: OperationProps) => {
  return <>
      {operation.deprecated ? <BreadCrumbsDeprecatedWrapper>
          <Breadcrumbs item={operation} />
        </BreadCrumbsDeprecatedWrapper> : <BreadCrumbsWrapper>
          <Breadcrumbs item={operation} />
        </BreadCrumbsWrapper>}
    </>;
};
const OperationUrlContainer = ({
  operation,
  url,
  isLoggedIn = false
}) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);
  const copyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(url);
  };
  return <URLTextWrapContainer>
      <HttpVerb type={operation.httpVerb}>{operation.httpVerb}</HttpVerb>{' '}
      {!isCopied ? <ToolTipContainer title={url}>
          <ServerRelativeURL>{url}</ServerRelativeURL>
        </ToolTipContainer> : <CopiedText>{__('Copied to clipboard')}</CopiedText>}
      {isLoggedIn && !operation.isWebhook && (!isCopied ? <CopyClipboardButton onClick={copyToClipboard}>
            <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 0.699974C1.46667 0.699974 1 0.899975 0.6 1.29997C0.2 1.69997 0 2.16664 0 2.69997V22.8H2V2.69997H17.8V0.699974H2ZM6 4.69997C5.46667 4.69997 5 4.89997 4.6 5.29997C4.2 5.69997 4 6.16664 4 6.69997V25.3666C4 25.9 4.2 26.3666 4.6 26.7666C5 27.1666 5.46667 27.3666 6 27.3666H20.6667C21.2 27.3666 21.6667 27.1666 22.0667 26.7666C22.4667 26.3666 22.6667 25.9 22.6667 25.3666V6.69997C22.6667 6.16664 22.4667 5.69997 22.0667 5.29997C21.6667 4.89997 21.2 4.69997 20.6667 4.69997H6ZM6 6.69997H20.6667V25.3666H6V6.69997ZM6 6.69997V25.3666V6.69997Z" fill="black" />
            </svg>
          </CopyClipboardButton> : <CopiedIcon>
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.59948 15.8L0.132812 8.33336L1.56615 6.90003L7.59948 12.9334L20.3995 0.133362L21.8328 1.5667L7.59948 15.8Z" fill="#fc6423" />
            </svg>
          </CopiedIcon>)}
    </URLTextWrapContainer>;
};
@observer
class OperationURL extends React.Component<{
  operation: OperationType;
  store?: AppStore;
}> {
  render() {
    const {
      operation,
      store
    } = this.props;
    const user = store && store.user || new UserStore();
    const apiBaseUrl = user.loggedIn ? `https://${user.endpointUrl}` : '';
    return <EndpointInfo inverted={true}>
        {!user.loggedIn && <OperationBoxLoginInfo>
            {`${__('Please')} `}
            <LoginLink />
            {` ${__('to see full path URL.')} `}
            <a href={`${store?.options.docsUrl}/essentials/base-url`}>{__('Learn why.')}</a>
          </OperationBoxLoginInfo>}
        <OperationUrlContainer operation={operation} url={apiBaseUrl + operation.path} isLoggedIn={user.loggedIn} />
      </EndpointInfo>;
  }
}
class WebhookURL extends React.Component<{
  operation: OperationType;
}> {
  render() {
    const {
      operation
    } = this.props;
    const baseUrl = 'https://{yourDomain}';
    return <EndpointInfo inverted={true}>
        <OperationUrlContainer operation={operation} url={baseUrl + DEFAULT_WEBHOOK_PATH} />
      </EndpointInfo>;
  }
}
@observer
export class Operation extends React.Component<OperationProps> {
  render() {
    const {
      operation,
      getItemByOperationId
    } = this.props;
    const {
      name: summary,
      description,
      externalDocs,
      xIsEarlyAccess,
      isWebhook,
      deprecated
    } = operation;
    const hasDescription = !!(description || externalDocs);
    return <>
        {deprecated && <DeprecationBanner />}
        <OperationRow>
          <MiddlePanel>
            <OperationBreadcrumbs operation={operation} />
            <H1>
              <ShareLink to={operation.id} />
              {summary} {isWebhook && <Badge type="webhook">{` ${__('Webhook')} `}</Badge>}
              {xIsEarlyAccess && <Badge type="warning">{` ${__('Early access')} `}</Badge>}
            </H1>
            {deprecated && operation.xDeprecationInformation && !!getItemByOperationId && <DeprecationInformationBox xDeprecationInfo={operation.xDeprecationInformation} getItemByOperationId={getItemByOperationId} />}
            {hasDescription && <Description>
                {description !== undefined && <Markdown source={description} />}
                {externalDocs && <ExternalDocumentation externalDocs={externalDocs} />}
                {operation.xApiAdditionalInfo && <AdditionalInfo content={operation.xApiAdditionalInfo} />}
              </Description>}
            {operation.isWebhook && <WebhookURL operation={operation} /> || <StoreConsumer>
                {store => <OperationURL operation={operation} store={store} />}
              </StoreConsumer>}
            <Extensions extensions={operation.extensions} />
            <SecurityRequirements securities={operation.security} scopes={operation.xScopes} />
            {operation.xThrottlingInformation && <ThrottlingInfo throttlingInformation={operation.xThrottlingInformation} />}
            <Parameters parameters={operation.parameters} body={operation.requestBody} />
            <ResponsesList responses={operation.responses} />
            {operation.xAdditionalInfo && operation.xAdditionalInfo.markdown && <Markdown source={operation.xAdditionalInfo.markdown} />}
            <Survey />
          </MiddlePanel>
          <DarkRightPanel>
            <StoreConsumer>
              {store => store && <RequestSamplesContainer operation={operation} store={store} />}
            </StoreConsumer>
            <ResponseSamples operation={operation} />
          </DarkRightPanel>
        </OperationRow>
      </>;
  }
}