import * as React from 'react';
import { InfoBannerText, InfoBannerWrapper } from './styled.elements';
import { SpecStore } from '../../services';
const NOT_AVAILABLE = 'N/A';
export const InfoBanner = (props: {
  spec: SpecStore;
  environment?: string;
}): React.JSX.Element => {
  const generatedAt = props.spec.info['x-generatedAt'];
  return <InfoBannerWrapper>
      <InfoIcon />
      <InfoBannerText>
        {formatEnvironment(props.environment)} environment API reference refreshed at:{' '}
        {formatDate(generatedAt)}.
      </InfoBannerText>
    </InfoBannerWrapper>;
};
function formatEnvironment(environment?: string) {
  return environment || NOT_AVAILABLE;
}
function formatDate(inputDate?: string) {
  if (!inputDate) {
    return NOT_AVAILABLE;
  }
  const parsed = Date.parse(inputDate);
  if (!parsed) {
    return NOT_AVAILABLE;
  }
  return new Date(parsed).toUTCString();
}
const InfoIcon = (): React.JSX.Element => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="white" />
    <path d="M12.162 18.8513C11.8028 18.8513 11.4962 18.7244 11.2422 18.4704C10.9883 18.2165 10.8613 17.9099 10.8613 17.5507C10.8613 17.1914 10.9883 16.8848 11.2422 16.6309C11.4962 16.377 11.8028 16.25 12.162 16.25C12.5212 16.25 12.8278 16.377 13.0818 16.6309C13.3357 16.8848 13.4627 17.1914 13.4627 17.5507C13.4627 17.9099 13.3357 18.2165 13.0818 18.4704C12.8278 18.7244 12.5212 18.8513 12.162 18.8513ZM10.8613 14.3919V5.47296H13.4627V14.3919H10.8613Z" fill="#fc6423" />
  </svg>;