export class UserStore {
  endpointUrl?: string;
  accountHash?: string;
  apiKey?: string;
  phoneNumber?: string;
  userName?: string;
  loggedIn: boolean;
  allowedApiKeyView?: boolean;
  constructor() {
    this.loggedIn = false;
  }
}