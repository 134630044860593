import { observer } from 'mobx-react';
import * as React from 'react';
import { MiddlePanel } from '../../common-elements';
import { AppStore, CategoryMenuItem, ContentItemModel, MenuStore, ModuleMenuItem, PageMenuItem, ProductMenuItem } from '../../services';
import { CategoryContent, ModuleContent, ProductContent } from '../Group/GroupContent';
import { StoreConsumer } from '../StoreBuilder';
import { ContentItem, ContentItems } from './ContentItems';
import { HomePage } from '../Redoc/HomePage/HomePage';
import { CustomPagesStore } from '../Redoc/CustomPages/CustomPagesStore';
import { EmailGetStarted } from '../Redoc/CustomPages/EmailGetStarted';
import { SMSGetStarted } from '../Redoc/CustomPages/SMSGetStarted';
import { WhatsAppGetStarted } from '../Redoc/CustomPages/WhatsAppGetStarted';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

/*
Usage example:
  const customPages = new Map([
    ['programmable-communications/omni-chat', <OmniChat />],
    ['channels', <Channels />],
  ]);
*/

@observer
export class SingleContentItem extends React.Component<{
  menu: MenuStore;
  store: AppStore;
}> {
  render() {
    const {
      menu
    } = this.props;
    const activeItem = menu.flatItems[menu.activeItemIdx];
    if (!activeItem) {
      return this.renderHomePage(menu.flatItems[0]);
    }
    if (activeItem.name === 'Get Started') {
      return this.renderHomePage(menu.flatItems[0]);
    }
    const customPagesStore = this.createCustomPagesStore();
    const customPage = customPagesStore.getPage(activeItem.id);
    if (customPage) {
      return this.withBreadcrumbs(activeItem, customPage);
    }
    switch (activeItem.type) {
      case 'page':
        return <ContentItem item={(activeItem as PageMenuItem)} getItemByOperationId={menu.getItemByOperationId} />;
      case 'product':
        return this.withBreadcrumbs(activeItem, <ProductContent item={(activeItem as ProductMenuItem)} />);
      case 'module':
      case 'submodule':
        return this.withBreadcrumbs(activeItem, <ModuleContent item={(activeItem as ModuleMenuItem)} />);
      case 'category':
        return <CategoryContent item={(activeItem as CategoryMenuItem)} />;
      case 'operation':
      default:
        return <ContentItem item={activeItem} getItemByOperationId={menu.getItemByOperationId} />;
    }
  }
  private createCustomPagesStore() {
    return new CustomPagesStore(this.getCustomPages());
  }
  private getCustomPages() {
    return new Map([['channels/email', <EmailGetStarted key="email-get-started" store={this.props.store} />], ['channels/sms', <SMSGetStarted key="sms-get-started" store={this.props.store} />], ['channels/whatsapp', <WhatsAppGetStarted key="whatsapp-get-started" store={this.props.store} />]]);
  }
  private renderHomePage(defaultItem: ContentItemModel) {
    return <StoreConsumer>
        {store => {
        return store ? <MiddlePanel>
              <HomePage store={store} />
            </MiddlePanel> : <ContentItems items={[defaultItem]} />;
      }}
      </StoreConsumer>;
  }
  private withBreadcrumbs(activeItem: ContentItemModel, content: JSX.Element) {
    return <>
        <Breadcrumbs item={activeItem} />
        {content}
      </>;
  }
}