import * as React from 'react';
import { isJsonLike, langFromMime } from '../../utils/openapi';
import { JsonViewer } from '../JsonViewer/JsonViewer';
import { SourceCodeWithCopy } from '../SourceCode/SourceCode';
export interface ExampleValueProps {
  example: {
    value: any;
    expanded: boolean;
    responseCode?: string;
  };
  mimeType: string;
}
export function ExampleValue({
  example,
  mimeType
}: ExampleValueProps) {
  let {
    value
  } = example;
  if (isJsonLike(mimeType)) {
    return <JsonViewer example={example} />;
  } else {
    if (typeof value === 'object') {
      // just in case example was cached as json but used as non-json
      value = JSON.stringify(value, null, 2);
    }
    return <SourceCodeWithCopy lang={langFromMime(mimeType)} source={value} />;
  }
}