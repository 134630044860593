import * as React from 'react';
import { __ } from 'ib-i18n';
import { RightPanelHeader, Tab, TabList, TabPanel, Tabs } from '../../common-elements';
import { OperationModel, RedocNormalizedOptions } from '../../services';
import { CodeSampleModel } from '../../services/CodeSamplesFactory';
import { OptionsContext } from '../OptionsProvider';
import { PayloadSamples } from '../PayloadSamples/PayloadSamples';
import { CodeSample } from './CodeSamples';
export interface RequestSamplesProps {
  operation: OperationModel;
  samples: CodeSampleModel[];
}
export class RequestSamples extends React.Component<RequestSamplesProps> {
  static contextType = OptionsContext;
  context: RedocNormalizedOptions;
  operation: OperationModel;
  render() {
    const {
      operation
    } = this.props;
    const requestBodyContent = operation.requestBody && operation.requestBody.content;
    const hasBodySample = requestBodyContent && requestBodyContent.hasSample;
    const {
      samples
    } = this.props;
    const hasSamples = hasBodySample || samples.length > 0;
    const hideTabList = samples.length + (hasBodySample ? 1 : 0) === 1 ? this.context.hideSingleRequestSampleTab : false;
    return hasSamples && <div>
          <RightPanelHeader>{` ${__('Request samples')} `}</RightPanelHeader>

          <Tabs defaultIndex={0}>
            <TabList hidden={hideTabList}>
              {hasBodySample && <Tab key="payload">{` ${__('Payload')} `}</Tab>}
              {samples.map(sample => <Tab key={sample.lang + '_' + (sample.label || '')}>
                  {sample.label !== undefined ? sample.label : sample.lang}
                </Tab>)}
            </TabList>
            {hasBodySample && <TabPanel key="payload">
                <div>
                  <PayloadSamples content={requestBodyContent!} />
                </div>
              </TabPanel>}
            {samples.map(sample => <TabPanel key={sample.lang}>
                <CodeSample sample={sample} />
              </TabPanel>)}
          </Tabs>
        </div> || null;
  }
}