import * as React from 'react';
import { TitleSection, StyledPMedium, StyledDivMediumBox, PageContentList, StyledUl, BoxRow, AlertMinor, AlertMajor, UserLoggedBox, BtnPrimary, BtnBlank, BlackBox, HomeWrapper, PageContent } from './HomePageComponents';
import { H1 } from '../../../common-elements/headers';
import { AppStore } from '../../../services';
import { UserStore } from '../../../services/UserStore';
import { observer } from 'mobx-react';
import { FormattedHtmlMessage, __ } from 'ib-i18n';
export interface HomePageProps {
  store: AppStore;
}
@observer
export class HomePage extends React.Component<HomePageProps> {
  componentDidMount() {
    const menuItems = document?.querySelectorAll('#page-content-list li a');
    const menuItemsArr = Array.prototype.slice.call(menuItems);
    const scrollItems = menuItemsArr.map(item => {
      return document.querySelector(item.getAttribute('href'));
    });
    let lastId = '';
    window.onscroll = () => {
      const scrollOffset = 50;
      const fromTop = window.scrollY + scrollOffset;
      const cur = scrollItems.find(item => {
        if (item) {
          return item.offsetTop - scrollOffset > fromTop;
        }
        return false;
      });
      const id = cur ? cur.getAttribute('id') || '' : '';
      if (lastId !== id) {
        lastId = id;
        menuItemsArr.forEach(item => {
          item.parentNode.classList.remove('is-active');
        });
        const activeMenuItem = menuItemsArr.find(item => item.getAttribute('href') === `#${id}`);
        if (activeMenuItem) {
          activeMenuItem.parentNode.classList.add('is-active');
        }
      }
    };
  }
  render() {
    const store = this.props.store;
    const {
      signupUrl,
      loginUrl,
      docsUrl,
      portalUrl
    } = store.options;
    const user = store && store.user || new UserStore();
    const baseUrl = user.loggedIn ? `https://${user.endpointUrl}` : '';
    const scrollTo = (element, to, duration) => {
      if (duration <= 0) {
        return;
      }
      const difference = to - element.scrollTop;
      const perTick = difference / duration * 10;
      setTimeout(() => {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) {
          return;
        }
        scrollTo(element, to, duration - 10);
      }, 10);
    };
    const scrollToLink = e => {
      const el = e.target;
      const elHref = el?.getAttribute('href');
      const linkTitle = document?.querySelector(elHref);
      const offsetTop = linkTitle.offsetTop + 1;
      setTimeout(() => {
        linkTitle?.classList.add('is-active-link');
      }, 400);
      setTimeout(() => {
        linkTitle?.classList.remove('is-active-link');
      }, 1250);
      if (navigator.vendor.indexOf('Apple') > -1) {
        scrollTo(document.documentElement, offsetTop - 50, 600);
      } else {
        window.scrollTo({
          top: offsetTop - 120,
          behavior: 'smooth'
        });
      }
      e.preventDefault();
    };
    const copyToClipboard = str => {
      const doc = window.document;
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      doc.body.appendChild(el);
      const selected = doc.getSelection()?.rangeCount! > 0 ? doc.getSelection()?.getRangeAt(0) : false;
      el.select();
      doc.execCommand('copy');
      doc.body.removeChild(el);
      if (selected) {
        doc.getSelection()?.removeAllRanges();
        doc.getSelection()?.addRange(selected);
      }
    };
    const userBoxClick = e => {
      const clickedEl = e.target;
      const clickedElTarget = clickedEl?.getAttribute('data-target');
      if (clickedElTarget === 'apiKey-hidden') {
        const row = document?.querySelector('.box-row');
        const targetValue = document?.querySelector('[data-api-key="value"]');
        const targetHidden = document?.querySelector('[data-api-key="hidden"]');
        if (row?.classList.contains('shown')) {
          row?.classList.remove('shown');
          targetHidden?.classList.remove('hidden');
          targetValue?.classList.add('hidden');
        } else {
          row?.classList.add('shown');
          targetHidden?.classList.add('hidden');
          targetValue?.classList.remove('hidden');
        }
      } else {
        const targetValue = document?.getElementById(clickedElTarget)?.getAttribute('value');
        copyToClipboard(targetValue);
        (clickedEl as HTMLElement).parentElement?.parentElement?.classList.add('copied');
        setTimeout(() => {
          (clickedEl as HTMLElement).parentElement?.parentElement?.classList.remove('copied');
        }, 1000);
      }
    };
    return <HomeWrapper>
        <PageContent>
          <H1>{__('Get Started with Infobip API')}</H1>
          <StyledPMedium>
            {/* prettier-ignore */
          __('This documentation contains the entire Infobip API offering. One quick glance at the left-side navigation menu reveals the Infobip product stack. Each product contains documentation for every available API endpoint and a webhook.')}
          </StyledPMedium>
          {user.loggedIn ? <UserLoggedBox id="userLoggedInBox">
              <StyledDivMediumBox id="boxApiKey">
                <BoxRow className="box-row">
                  <div className="box-info">
                    <FormattedHtmlMessage message={__('<strong>API Key:</strong> <span>You can check your API keys {apiKeys}</span>.')} apiKeys={<a href={`${portalUrl}/settings/accounts/api-keys`}>{__('here')}</a>} />
                  </div>
                </BoxRow>
              </StyledDivMediumBox>
              <hr />
              <StyledDivMediumBox id="boxBaseUrl">
                <BoxRow className="box-row">
                  <div>
                    <strong>{__('Base URL:')}</strong>{' '}
                    <span data-input-border="true">{baseUrl}</span>
                    <input id="baseUrl" className="hidden" type="text" defaultValue={baseUrl} />
                  </div>
                  <div className="box-tools">
                    <i data-target="baseUrl" onClick={userBoxClick}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                      </svg>
                    </i>
                    <a className="link" href={`${docsUrl}/essentials/base-url`}>
                      {' '}
                      {__('Learn more')}
                    </a>
                  </div>
                </BoxRow>
              </StyledDivMediumBox>
            </UserLoggedBox> : <UserLoggedBox id="userAnonymousBox">
              <StyledPMedium>
                <FormattedHtmlMessage message={__('Remember to {login} ')} login={<a href={`${loginUrl}https%3A%2F%2Fwww.infobip.com%2Fdocs%2Fapi`}>
                      {__('log in!')}
                    </a>} />
                {/* prettier-ignore */
            __('Documentation and code examples are personalized making them easier for you to use.')}
              </StyledPMedium>
              <BtnPrimary href={signupUrl}>{__('Create account')}</BtnPrimary>
              <BtnBlank href={`${loginUrl}https%3A%2F%2Fwww.infobip.com%2Fdocs%2Fapi`}>
                {__('Log in')}
              </BtnBlank>
            </UserLoggedBox>}

          <TitleSection id="trial-account">{__('Trial account')}</TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('While Infobip API is free to explore for everyone, you will need an Infobip account to use it. After the quick sign-up process, you will get your API key and Base URL which is all you need to start using any API. During the trial period you will have some limitations depending on the product you choose. Typically, these are:')}
          </StyledPMedium>
          <StyledUl>
            <li>{__('the number of free messages you can send per channel')}</li>
            <li>
              {/* prettier-ignore */
            __('the type of recipients - usually, you\'ll only be able to send messages to your own number or email account registered when signing up')}
            </li>
            <li>
              {__('sender IDs - you will only be able to use a predefined Infobip demo sender')}
            </li>
          </StyledUl>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('The trial is valid until you spend all your {freeUnits}. Similarly to a paying account, the performance of your API is influenced by throttling, throughput, and other carrier-related restrictions. Learn more about the trial {here}.')} freeUnits={<a href={`${docsUrl}/essentials/free-trial#whatdo-yougetwith-channels-free-trial-`} target="_blank">
                  {__('free units')}
                </a>} here={<a href={`${docsUrl}/essentials/free-trial`} target="_blank">
                  {__('here')}
                </a>} />
          </StyledPMedium>
          <TitleSection id="authentication">{__('Authentication')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('We support {authMethods}. Our recommendation is to use API key header whenever possible.')} authMethods={<a href={`${docsUrl}/essentials/api-essentials/api-authentication`} target="blank">
                  {__('multiple authentication methods')}
                </a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('Once logged in, find your API key at the top of this page, within the web interface {homepage}, or the {apiKeyManagement}.')} homepage={<a href={`${portalUrl}/homepage`}>{__('homepage')}</a>} apiKeyManagement={<a href={`${portalUrl}/settings/accounts/api-keys`}>
                  {__('API key management page')}
                </a>} />
          </StyledPMedium>
          <BlackBox>
            <div className="header">
              <b>{__('API key authentication header example')}</b>
            </div>
            <div className="content">
              <div className="syntaxhighlight">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="container" title="Hint: double-click to select code">
                          <div className="line">
                            <code className="js plain">
                              {`${__('Authorization: App')} `}
                              003026bbc133714df1834b8638bb496e-8f4b3c9a-e931-478d-a994-28a725159ab9
                            </code>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BlackBox>
          <TitleSection id="base-url-and-data-centers">
            {__('Base URL and data centers')}
          </TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('Each Infobip account has its unique Base URL which you should use in every API request you make. If you are logged into Infobip, you will find your Base URL at the top of this page, and it will already be injected in every API endpoint and code example throughout all documentation.')}
          </StyledPMedium>
          <BlackBox>
            <div className="header">
              <b>{__('API key authentication header example')}</b>
            </div>
            <div className="content">
              <div className="syntaxhighlight">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="container" title="Hint: double-click to select code">
                          <div className="line">
                            <code className="js comments">
                              {/* prettier-ignore */
                            __('// Base URL shown below is only a demo, not your base URL. Do not use it in your code.')}
                            </code>
                          </div>
                          <div className="line">
                            <code className="js plain">{__('Base URL: example.infobip.com')}</code>
                          </div>
                          <div className="line">
                            <FormattedHtmlMessage message={/* prettier-ignore */
                          __('{baseUrl} {keyWord} {endpoint}')} baseUrl={<code className="js plain">{__('Base URL used')}</code>} keyWord={<code className="js keyword">{__('in')}</code>} endpoint={<code className="js plain">
                                  {/* prettier-ignore */__('the send SMS endpoint: https://example.infobip.com/sms/2/text/advanced')}
                                </code>} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BlackBox>
          <StyledPMedium>
            {/* prettier-ignore */
          __('We use the base URL to route your request to the correct "regulatory region" and to optimize your traffic between data centers with the region. Base URL is also used by our Support teams for troubleshooting.')}
          </StyledPMedium>
          <AlertMinor>
            <h5>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#35a246">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                </svg>
              </i>
              {__('NOTE')}
            </h5>
            <StyledPMedium>
              {/* prettier-ignore */
            __('Base URL is not a secret. Someone else using your base URL won\'t harm your account in any way. However, if you use a base URL other than your own, you may experience service degradation or even a complete service failure.')}
            </StyledPMedium>
          </AlertMinor>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('Learn more about {baseUrls}.')} baseUrls={<a href={`${docsUrl}/essentials/base-url`}>{__('Base URLs')}</a>} />
            {`${__('Learn more about')} `}
            <a href={`${docsUrl}/essentials/base-url`}>{__('Base URLs')}</a>.
          </StyledPMedium>

          <TitleSection id="openapispec">{__('OpenAPI Specification')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('To ensure Infobip APIs comply with the industry standards and can be easily integrated, they are all designed according to the {openApiSpec} framework.')} openApiSpec={<a href="https://swagger.io/specification/">{__('OpenAPI Specification')}</a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('See the {openApiSpec}.')} openApiSpec={<a href="https://dev.infobip.com/openapi-specifications">
                  {__('Infobip OpenAPI Specification')}
                </a>} />
          </StyledPMedium>

          <TitleSection id="sdks">{__('SDKs')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('We, at Infobip provide you with official API client libraries and SDKs in various programming languages. To see all SDKs, refer to our {sdks} page in our {devHub}.')} sdks={<a href="https://www.infobip.com/developers/sdks">{__('SDKs')}</a>} devHub={<a href="https://www.infobip.com/developers">{__('Developer Hub')}</a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('When it comes to new releases, we follow what is known as {semanticVersioning} standard. In short, that means when we release patches or minor updates, you are safe to update to a new version, because backward compatibility is preserved. All major releases will always contain changes incompatible with previous versions.')} semanticVersioning={<a href="https://semver.org/">{__('Semantic Versioning 2.0.0')}</a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('All our API-related SDKs are open sourced and available on GitHub. Our libraries are built on top of the {openApiSpec} and powered by {openApiGenerator}. This means we do not accept pull requests on those GitHub repositories. However, we strongly encourage you to open new issues in our repositories, as stated in contributing guidelines.')} openApiSpec={<a href="https://swagger.io/specification/" target="_blank">
                  {__('OpenAPI Specification')}
                </a>} openApiGenerator={<a href="https://openapi-generator.tech/" target="_blank">
                  {__('OpenAPI Generator')}
                </a>} />
          </StyledPMedium>
          <AlertMajor>
            <h5>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fc6423">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                </svg>
              </i>
              {__('Major release upgrading')}
            </h5>
            <StyledPMedium>
              {/* prettier-ignore */
            __('When upgrading to a major version, carefully review the specific library changelog, read the relevant migration guide where available, and, of course, test the newly upgraded integration.')}
            </StyledPMedium>
          </AlertMajor>

          <TitleSection id="code-examples">{__('Code examples')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('For each API endpoint, we provide one or more use case examples. Each use case has including/excluding parameters on how to complete a task (e.g., Send basic text SMS, send scheduled SMS, send SMS in a specific language, etc.) Each code example is available in multiple programming languages, as well as cURL command, and as a request payload in JSON or XML. Code examples are auto generated from the {openApiSpec} using the {postmanCodeGenerator}. You can copy code examples and use them in your project.')} openApiSpec={<a href="https://openapis.org/">{__('Open API specification')}</a>} postmanCodeGenerator={<a href="https://github.com/postmanlabs/postman-code-generators">
                  {__('Postman code generator')}
                </a>} />
          </StyledPMedium>

          <TitleSection id="webhooks">{__('Webhooks')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('If you want to be notified about certain events, such as message delivered status or incoming message status, use webhooks. Webhooks are standard HTTP endpoints implemented in your application that will accept and handle HTTP requests from Infobip. Basically, webhooks save you from having to continuously send requests to the Infobip server asking for message delivery status. Instead, available delivery statuses are forwarded to a provided endpoint. These requests are usually POST requests containing payload in JSON format. Manage and configure your webhooks using the {subscriptionManagementApi}.')} subscriptionManagementApi={<a href={`${docsUrl}/api/platform/subscriptions-api`}>
                  {__('Subscription Management API')}
                </a>} />
          </StyledPMedium>
          <BlackBox>
            <div className="header">
              <b>{__('SMS delivery report webhook')}</b>
            </div>
            <div className="content">
              <div className="syntaxhighlight">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="container" title={__('Hint: double-click to select code')}>
                          <div className="line">
                            <code className="java color1">@PostMapping</code>
                            <code className="java plain">(</code>
                            <code className="java string">"/delivery-reports"</code>
                            <code className="java plain">)</code>
                          </div>
                          <div className="line">
                            <code className="java keyword">public </code>
                            <code className="java keyword">void </code>
                            <code className="java plain">
                              receiveDr(HttpServletRequest request){' '}
                            </code>
                            <code className="java keyword">throws </code>
                            <code className="java plain">IOException &#123;</code>
                          </div>
                          <div className="line">
                            <code className="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code>
                            <code className="java plain">SmsDeliveryResult reports = </code>
                            <code className="java keyword">new </code>
                            <code className="java plain">
                              JSON().getGson().fromJson(request.getReader(), SmsDeliveryResult.
                            </code>
                            <code className="java keyword">class</code>
                            <code className="java plain">);</code>
                          </div>
                          <div className="line number4 index3 alt1">
                            <code className="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code>
                            <code className="java keyword">for </code>
                            <code className="java plain">
                              (SmsReport report : reports.getResults()) &#123;
                            </code>
                          </div>
                          <div className="line">
                            <code className="java spaces">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </code>
                            <code className="java plain">
                              System.out.println(report.getMessageId() +{' '}
                            </code>
                            <code className="java string">" - "</code>
                            <code className="java plain">+ report.getStatus().getName());</code>
                          </div>
                          <div className="line">
                            <code className="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code>
                            <code className="java plain">&#125;</code>
                          </div>
                          <div className="line">
                            <code className="java plain">&#125;</code>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BlackBox>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('If you want to protect your endpoints from spam, you can use our IP addresses to whitelist the traffic from the Infobip platform. Check out the {safeList}.')} safeList={<a href={`${docsUrl}/essentials/ip-address-safelist`}>
                  {__('safe list of IP addresses')}
                </a>} />
            {/* prettier-ignore */
          `${__('If you want to protect your endpoints from spam, you can use our IP addresses to whitelist the traffic from the Infobip platform. Check out the')} `}
            <a href={`${docsUrl}/essentials/ip-address-safelist`}>
              {__('safe list of IP addresses.')}
            </a>
          </StyledPMedium>

          <TitleSection id="status-and-error-codes">{__('Status and error codes')}</TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('When it comes to status codes, we provide the <strong>result status codes</strong> (which are sent back by the server for any HTTP transaction), as well as API status codes. Find the <strong>API Status codes</strong> in the response of sent message(s), delivery reports and/or message logs. They are there to help you track the status of sent messages, but can also be very useful when troubleshooting. Error codes, similar to status codes, can also be returned as part of the response for sent message(s) and delivery reports.')} />
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('Usually, the status you might receive within a response is one with the ID 26, called PENDING_ACCEPTED. It notifies you that the message you wanted to send has been accepted, processed, and it\'s ready to be sent to the operator.')}
          </StyledPMedium>
          <BlackBox>
            <div className="header">
              <b>{__('Status object example')}</b>
            </div>
            <div className="content">
              <div className="syntaxhighlight">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="container" title="Hint: double-click to select code">
                          <div className="line number1 index0 alt2">
                            <code className="json plain">&#123;&nbsp; </code>
                          </div>
                          <div className="line number2 index1 alt1">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"groupId"</code>
                            <code className="json plain">: </code>
                            <code className="json constants">1</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line number3 index2 alt2">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"groupName"</code>
                            <code className="json plain">: </code>
                            <code className="json string">"PENDING"</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line number4 index3 alt1">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"id"</code>
                            <code className="json plain">: </code>
                            <code className="json constants">26</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line number5 index4 alt2">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"name"</code>
                            <code className="json plain">: </code>
                            <code className="json string">"PENDING_ACCEPTED"</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line number6 index5 alt1">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"description"</code>
                            <code className="json plain">: </code>
                            <code className="json string">
                              "Message has been accepted and processed."
                            </code>
                          </div>
                          <div className="line number7 index6 alt2">
                            <code className="json plain">&#125;</code>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BlackBox>
          <StyledPMedium>
            {/* prettier-ignore */
          __('When it comes to response statuses, the point is to receive one without an error. For example, one with the ID 0 with the name NO_ERROR.')}
          </StyledPMedium>
          <BlackBox>
            <div className="header">
              <b>{__('Status object example')}</b>
            </div>
            <div className="content">
              <div className="syntaxhighlight">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="container" title="Hint: double-click to select code">
                          <div className="line">
                            <code className="json plain">&#123;&nbsp; </code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"groupId"</code>
                            <code className="json plain">: </code>
                            <code className="json constants">0</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"groupName"</code>
                            <code className="json plain">: </code>
                            <code className="json string">"OK"</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"id"</code>
                            <code className="json plain">: </code>
                            <code className="json constants">0</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"name"</code>
                            <code className="json plain">: </code>
                            <code className="json string">"NO_ERROR"</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"description"</code>
                            <code className="json plain">: </code>
                            <code className="json string">"No Error"</code>
                            <code className="json plain">,</code>
                          </div>
                          <div className="line">
                            <code className="json spaces">&nbsp;&nbsp;&nbsp;</code>
                            <code className="json variable">"permanent"</code>
                            <code className="json plain">: </code>
                            <code className="json value">false</code>
                          </div>
                          <div className="line">
                            <code className="json plain">&#125;</code>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </BlackBox>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('For detailed information, refer to {responseStatus}.')} responseStatus={<a href={`${docsUrl}/essentials/response-status-and-error-codes`}>
                  {__('Response Statuses and Error Codes')}
                </a>} />
          </StyledPMedium>

          <TitleSection id="versioning-and-api-lifecycle">
            {__('Versioning and API lifecycle')}
          </TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('The URL of each documented API endpoint contains a current API version number. The endpoint version will change only if a breaking change is introduced. Adding a new field, for example, is not considered a breaking change. However, removing a field or a model structure change is a breaking change. When you introduce a new version, the old one is labeled in documentation as "deprecated".')}
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('We will never completely retire an API endpoint without notifying you at least 6 months in advance.')}
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('There are exceptions to this rule. For example, for products and endpoints labeled as "early access". In those cases, you need to be prepared to keep up, iterate faster, and experiment with us. Even though the endpoint is flagged as early access, if we detect in our logs that you have been using the API recently, we will notify you about the change.')}
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('As always, we appreciate your feedback but in this phase, it\'s especially valuable to us, so please reach out to us.')}
          </StyledPMedium>

          <TitleSection id="throughput-and-throttling">
            {__('Throughput and throttling')}
          </TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('The question we often get is how much traffic can our API handle. This depends on which API endpoint is called.')}
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('Message sending APIs do not have an upper limit. Many of the largest global enterprises are sending messages over our APIs and we have enough capacity to handle their traffic peaks with resources to spare. However, be aware of bottlenecks that may occur with specific channel providers that sit between Infobip and message recipients. For more details, visit {channelDocumentation} for the product you are interested in.')} channelDocumentation={<a href={`${docsUrl}/product-documentation`}>{__('channel documentation')}</a>} />
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          `${__('Every API endpoint that is not sending messages is configured to throttle requests, if the usage goes well over its common use cases. If this happens, you will get an HTTP status code 429 (Too Many Requests). It is usually a sign that you are using the API endpoint in a way that was not designed to be used. In these situations, you may want to explore other endpoints which may be more suitable for your use case, or you could reach out for advice.')} `}
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('Our throttling mechanism is configured based on the maximum number of requests in a unit of time. So if you stop sending API requests and wait it out a bit, you will be able to call the API again.')}
          </StyledPMedium>

          <TitleSection id="best-practices">{__('Best practices')}</TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('We are working extra hard to make the smoothest experience for developers using our API. One of the ways we try to make that happen is by focusing on consistency in following certain approaches, standards, and practices.')}
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('You may have noticed that code examples in our documentation are not URL encoded. If are dependent on a system, programming language, or library that requires explicit URL encoding for request query parameters, you will need to do it yourself. The major reason behind such a decision was that we simply want to keep our code examples easy to read and understand. Moreover, for the majority of programming languages and libraries which trigger HTTP calls, that will be done under the hood.')}
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('Refer to {integrationBestPractices} for more information about how to avoid potential issues, supported date formats, request/response body types, and other coding practices we recommend you follow while using our API.')} integrationBestPractices={<a href={`${docsUrl}/essentials/integration-best-practices`}>
                  {__('Integration Best Practices')}
                </a>} />
          </StyledPMedium>
          <StyledPMedium>
            {/* prettier-ignore */
          __('Best practices are here to make all of our lives easier, so don’t shy away from checking them in more detail.')}
          </StyledPMedium>

          <TitleSection id="integrations-and-partnership-program">
            {__('Integrations and partnership program')}
          </TitleSection>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('Before you start working on your integration solution, you might want to explore existing {integrations} to check if someone hasn\'t already created one and made it available for others to use.')} integrations={<a href="https://partners.infobip.com/platforms">{__('integrations')}</a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={__('If you believe that your solution could be interesting for a broader audience, we invite you to reach out to the {partnershipTeam}. We love seeing how other platforms benefit from our connectivity offering.')} partnershipTeam={<a href="https://www.infobip.com/partnership/become-a-partner">
                  {__('Partnerships team')}
                </a>} />
          </StyledPMedium>
          <StyledPMedium>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('Our {partnershipProgram} offers many benefits such as free professional services, training and certification from our global experts, on-site sales and presales support, and marketing development funds.')} partnershipProgram={<a href="https://partners.infobip.com/">{__('Partnership Program')}</a>} />
          </StyledPMedium>

          <TitleSection id="help-and-support">{__('Help and support')}</TitleSection>
          <StyledPMedium>
            {/* prettier-ignore */
          __('If you got stuck, here\'s what you can do:')}
          </StyledPMedium>
          <StyledUl>
            <li>
              <FormattedHtmlMessage message={__('Check the {uptimeStatus} of the product in use.')} uptimeStatus={<a href="https://status.infobip.com">{__('uptime status')}</a>} />
            </li>
            <li>
              <FormattedHtmlMessage message={/* prettier-ignore */
            __('Use our {documentationHub}. There\'s a good chance you\'ll find your answers there.')} documentationHub={<a href={docsUrl}>{__('Documentation Hub')}</a>} />
            </li>
            <li>
              <FormattedHtmlMessage message={/* prettier-ignore */
            __('If you have a more general question or you\'re struggling to get something to work, consider {stackOverflow} or {twitter}.')} stackOverflow={<a href="https://stackoverflow.com/questions/tagged/infobip">Stack Overflow</a>} twitter={<a href="https://twitter.com/Infobip">Twitter</a>} />
            </li>
            <li>
              <FormattedHtmlMessage message={/* prettier-ignore */
            __('If you\'ve found a bug or have an account-specific question, {createTicket} for our Support team.')} createTicket={<a href={`${portalUrl}/homepage/#support`}>{__('create a ticket')}</a>} />
            </li>
          </StyledUl>
        </PageContent>
        <PageContentList id="page-content-list">
          <p>{__('On This Page')}</p>
          <ul>
            <li>
              <a onClick={scrollToLink} href="#trial-account">
                {__('Trial account')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#authentication">
                {__('Authentication')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#base-url-and-data-centers">
                {__('Base URL and data centers')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#sdks">
                {__('SDKs')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#code-examples">
                {__('Code examples')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#webhooks">
                {__('Webhooks')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#status-and-error-codes">
                {__('Status and error codes')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#versioning-and-api-lifecycle">
                {__('Versioning and API lifecycle')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#throughput-and-throttling">
                {__('Throughput and throttling')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#best-practices">
                {__('Best practices')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#integrations-and-partnership-program">
                {__('Integrations and partnership program')}
              </a>
            </li>
            <li>
              <a onClick={scrollToLink} href="#help-and-support">
                {__('Help and support')}
              </a>
            </li>
          </ul>
        </PageContentList>
      </HomeWrapper>;
  }
}