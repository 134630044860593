import * as JsonSchemaRefParser from 'json-schema-ref-parser';
/* tslint:disable-next-line:no-implicit-dependencies */
import { OpenAPISpec } from '../types';
export async function loadAndBundleSpec(specUrlOrObject: object | string): Promise<OpenAPISpec> {
  const parser = new JsonSchemaRefParser();
  return (await parser.bundle(specUrlOrObject, ({
    resolve: {
      http: {
        withCredentials: false
      }
    }
  } as object)) as any);
}