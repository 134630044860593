import { OpenAPIOperation, OpenAPIParameter, OpenAPIPath, OpenAPITag, Referenced } from '../types';
import { isOperationName, SECURITY_DEFINITIONS_COMPONENT_NAME, setSecuritySchemePrefix } from '../utils';
import { MarkdownRenderer } from './MarkdownRenderer';
import { CategoryMenuItem, GroupModel, ModuleMenuItem, OperationModel, PageMenuItem, ProductMenuItem, SectionMenuItem, SubmoduleMenuItem } from './models';
import { OpenAPIParser } from './OpenAPIParser';
import { RedocNormalizedOptions } from './RedocNormalizedOptions';
export type ExtendedOpenAPIOperation = {
  pathName: string;
  httpVerb: string;
  pathParameters: Array<Referenced<OpenAPIParameter>>;
} & OpenAPIOperation;
export type ContentItemModel = GroupModel | OperationModel;
type OrderedTag = {
  order: number;
} & OpenAPITag;
class ProductStructureNode {
  nodes: Map<string, ProductStructureNode> = new Map<string, ProductStructureNode>();
  tag?: OrderedTag;
  constructor(tag?: OrderedTag) {
    this.tag = tag;
  }
}
class OperationDefinition {
  operationSpec: ExtendedOpenAPIOperation;
  isWebhook: boolean;
  constructor(operationSpec: ExtendedOpenAPIOperation, isWebhook: boolean) {
    this.operationSpec = operationSpec;
    this.isWebhook = isWebhook;
  }
}
export class MenuBuilder {
  /**
   * Builds page content structure based on tags
   */
  static buildStructure(parser: OpenAPIParser, options: RedocNormalizedOptions): ContentItemModel[] {
    const spec = parser.spec;
    const items: ContentItemModel[] = [];
    const tagOperations: Map<string, OperationDefinition[]> = new Map();
    const tagNameToDefinition: Map<string, OrderedTag> = new Map<string, OrderedTag>(spec.tags?.map((tag, index) => [tag.name, {
      ...tag,
      order: index
    }]) || []);
    const productStructureRoot = new ProductStructureNode();
    const productStructureBuilder = (pathName: string, path: OpenAPIPath, isWebhook: boolean) => {
      Object.keys(path).filter(isOperationName).forEach(operationName => {
        const operationInfo = path[operationName];
        const operationTags = operationInfo.tags || [];
        let currentNode = productStructureRoot;
        let currentTag;
        for (const tagName of operationTags) {
          const nextTag = tagNameToDefinition.get(tagName);
          if (nextTag) {
            let nextNode = currentNode.nodes.get(tagName);
            if (!nextNode) {
              nextNode = new ProductStructureNode(nextTag);
              currentNode.nodes.set(tagName, nextNode);
              if (isWebhook) {
                currentNode.nodes = new Map<string, ProductStructureNode>(Array.from(currentNode.nodes.entries()).sort((a, b) => !b[1].tag ? -1 : !a[1].tag ? 1 : a[1].tag.order - b[1].tag.order));
              }
            }
            currentNode = nextNode;
            currentTag = nextTag;
          }
        }
        if (currentTag) {
          const name = currentTag.name;
          if (!tagOperations.has(name)) {
            tagOperations.set(name, []);
          }
          tagOperations.get(name)?.push(new OperationDefinition({
            ...operationInfo,
            pathName,
            httpVerb: operationName,
            pathParameters: path.parameters || []
          }, isWebhook));
        }
      });
    };
    for (const [pathName, path] of Object.entries(spec.paths)) {
      productStructureBuilder(pathName, path, false);
    }
    const webhooks = spec.webhooks || spec['x-webhooks'];
    if (webhooks) {
      for (const [webhookName, path] of Object.entries(webhooks)) {
        productStructureBuilder(webhookName, path, true);
      }
    }
    const productStructureReader = (node: ProductStructureNode, readItems: ContentItemModel[], parentItem?: ContentItemModel) => {
      if (node.tag) {
        const item = MenuBuilder.generateItem(node.tag, parentItem);
        if (parentItem) {
          if (parentItem.childrenType && parentItem.childrenType !== item.type) {
            return;
          }
          parentItem.childrenType = item.type;
        }
        readItems.push(item);
        node.nodes.forEach(childNode => productStructureReader(childNode, item.items, item));
        tagOperations.get(node.tag.name)?.forEach(definition => {
          if (item.childrenType && item.childrenType !== 'operation') {
            return;
          }
          item.items?.push(new OperationModel(parser, definition.operationSpec, item, options, item.depth + 1, definition.isWebhook));
          item.childrenType = 'operation';
        });
      }
    };
    items.push(...MenuBuilder.addPageItems(spec.info.description || '', undefined, 1, options));
    productStructureRoot.nodes.forEach(categoryNode => productStructureReader(categoryNode, items));
    return items;
  }
  private static generateItem(tag: OpenAPITag, parentItem: ContentItemModel | undefined): GroupModel {
    switch (tag['x-type']) {
      case 'category':
        return new CategoryMenuItem(tag);
      case 'product':
        return new ProductMenuItem(tag, parentItem);
      case 'module':
        return new ModuleMenuItem(tag, parentItem);
      case 'submodule':
        return new SubmoduleMenuItem(tag, parentItem);
      default:
        return new SectionMenuItem(tag, parentItem);
    }
  }

  /**
   * extracts items from markdown description
   * @param description - markdown source
   */
  private static addPageItems(description: string, parent: GroupModel | undefined, initialDepth: number, options: RedocNormalizedOptions): ContentItemModel[] {
    const renderer = new MarkdownRenderer(options);
    const headings = renderer.extractHeadings(description || '');
    const mapHeadingsDeep = (_parent, items, depth = 1) => items.map(heading => {
      const group = new PageMenuItem(heading);
      group.depth = depth;
      if (heading.items) {
        group.items = mapHeadingsDeep(group, heading.items, depth + 1);
      }
      if (MarkdownRenderer.containsComponent(group.description || '', SECURITY_DEFINITIONS_COMPONENT_NAME)) {
        setSecuritySchemePrefix(group.id + '/');
      }
      return group;
    });
    return mapHeadingsDeep(parent, headings, initialDepth);
  }
}