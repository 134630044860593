import { CodeSampleLanguageProvider, CodeSamplesLanguage, FixedLanguageProvider } from './CodeSampleLanguageProvider';
import { FieldModel, OperationModel } from './models';
import { UserStore } from './UserStore';
const codeGenerators = {
  [FixedLanguageProvider.LANGUAGE_CURL]: require('postman-code-generators/codegens/curl'),
  [FixedLanguageProvider.LANGUAGE_JAVA]: require('postman-code-generators/codegens/java-okhttp'),
  [FixedLanguageProvider.LANGUAGE_CSHARP]: require('postman-code-generators/codegens/csharp-restsharp'),
  [FixedLanguageProvider.LANGUAGE_GOLANG]: require('postman-code-generators/codegens/golang'),
  [FixedLanguageProvider.LANGUAGE_PYTHON]: require('postman-code-generators/codegens/python-http.client'),
  [FixedLanguageProvider.LANGUAGE_PHP]: require('postman-code-generators/codegens/php-curl'),
  [FixedLanguageProvider.LANGUAGE_JAVASCRIPT]: require('postman-code-generators/codegens/js-jquery'),
  [FixedLanguageProvider.LANGUAGE_NODEJS]: require('postman-code-generators/codegens/nodejs-native'),
  [FixedLanguageProvider.LANGUAGE_RUBY]: require('postman-code-generators/codegens/ruby')
};
const SdkRequestBody = require('postman-collection/lib/collection/request-body');
const SdkQueryParam = require('postman-collection/lib/collection/query-param');
const SdkRequest = require('postman-collection/lib/collection/request');
export interface ExampleSource {
  name: string;
  source: string;
}
export interface CodeSampleModel {
  lang: string;
  label?: string;
  sources: ExampleSource[];
}
export class CodeSamplesFactory {
  private static readonly BASE_URL = '{baseUrl}';
  private static readonly PROTOCOL = 'https://';
  private static readonly AUTH_HEADER = 'Authorization: {authorization}';
  private static readonly CONTENT_TYPE_HEADER = 'Content-Type: application/json';
  private static readonly ACCEPT_HEADER = 'Accept: application/json';
  private static readonly TARGET_CONTENT_TYPE = 'application/json';
  private static readonly SUPPORTED_EMPTY_BODY_METHODS = ['GET', 'HEAD', 'DELETE'];
  private readonly languageProvider: CodeSampleLanguageProvider;
  constructor(languageProvider: CodeSampleLanguageProvider) {
    this.languageProvider = languageProvider;
  }
  async create(operationModel: OperationModel, user: UserStore): Promise<CodeSampleModel[]> {
    if (operationModel.codeSamples.length) {
      return operationModel.codeSamples.map(xCodeSampleFromSpec => {
        return {
          ...xCodeSampleFromSpec,
          sources: [{
            name: xCodeSampleFromSpec.label || xCodeSampleFromSpec.lang,
            source: xCodeSampleFromSpec.source
          }]
        };
      });
    }
    const targetContentTypeExamples = this.extractTargetContentTypeExamples(operationModel);
    if (this.isUnsupportedOperation(operationModel, targetContentTypeExamples)) {
      return [];
    }
    const output: CodeSampleModel[] = [];
    const languages = this.languageProvider.getSupportedLanguages();
    for (const language of languages) {
      const sources: ExampleSource[] = [];
      if (targetContentTypeExamples.length > 0) {
        for (const example of targetContentTypeExamples) {
          const source = await this.convertToSource(language, operationModel, user, example);
          sources.push(source);
        }
      } else {
        const source = await this.convertToSource(language, operationModel, user);
        sources.push(source);
      }
      output.push({
        lang: language.key,
        label: language.label,
        sources
      });
    }
    return output;
  }
  private convertToSource(language: CodeSamplesLanguage, operation: OperationModel, user: UserStore, example?: ExampleSource) {
    if (!codeGenerators.hasOwnProperty(language.key)) {
      throw new Error('Unknown language');
    }
    const codegen = codeGenerators[language.key];
    return new Promise<ExampleSource>((resolve, reject) => {
      codegen.convert(this.createRequest(operation, user, example?.source, language.key), language.options, (error, snippet) => {
        if (error) {
          reject(error);
        } else {
          if (language.key === 'python' || language.key === 'nodejs') {
            snippet = this.preventDoubleEncoding(snippet, operation.parameters);
          }
          resolve({
            name: example?.name || language.label,
            source: snippet
          });
        }
      });
    });
  }
  private extractTargetContentTypeExamples(operationModel: OperationModel): ExampleSource[] {
    const requestBodyContent = operationModel.requestBody && operationModel.requestBody.content;
    const hasRequestSample = requestBodyContent && requestBodyContent.hasSample;
    if (!hasRequestSample) {
      return [];
    }
    const isTargetContentPredicate = mediaType => {
      return mediaType.name === CodeSamplesFactory.TARGET_CONTENT_TYPE && mediaType.isRequestType;
    };
    const foundExamples = requestBodyContent?.mediaTypes.find(isTargetContentPredicate)?.examples;
    const result: ExampleSource[] = [];
    for (const name in foundExamples) {
      if (foundExamples.hasOwnProperty(name)) {
        const example = foundExamples[name];
        const source = example.value;
        if (source) {
          result.push({
            name,
            source
          });
        }
      }
    }
    return result;
  }
  private isUnsupportedOperation(operationModel: OperationModel, examples: ExampleSource[]) {
    const hasNoExamples = !examples || examples.length === 0;
    const needsRequestBody = !CodeSamplesFactory.SUPPORTED_EMPTY_BODY_METHODS.includes(operationModel.httpVerb.toUpperCase());
    return operationModel.isWebhook || hasNoExamples && needsRequestBody;
  }
  private createRequest(operationModel: OperationModel, user: UserStore, example: string | undefined, language: string): object {
    const request = new SdkRequest.Request({
      id: operationModel.operationId,
      method: operationModel.httpVerb,
      url: this.createUrl(operationModel, user),
      body: example && this.createJsonRequestBody(example, language)
    });
    this.addHeadersToRequest(operationModel, request);
    request.addQueryParams(this.createParameters(operationModel, 'query'));
    return request;
  }
  private createUrl(operationModel: OperationModel, user: UserStore): string {
    const baseUrl = user.loggedIn && user.endpointUrl || CodeSamplesFactory.BASE_URL;
    let path = operationModel.path;
    for (const parameter of (operationModel.parameters as FieldModel[])) {
      if (parameter.in === 'path' && parameter.example) {
        path = path.replace(`{${parameter.name}}`, parameter.example);
      }
    }
    return CodeSamplesFactory.PROTOCOL.concat(baseUrl, path);
  }
  private createJsonRequestBody(example: string, language: string): object | null {
    const space = language === 'curl' ? 2 : 0;
    return new SdkRequestBody.RequestBody({
      mode: SdkRequestBody.RequestBody.MODES.raw,
      raw: JSON.stringify(example, null, space)
    });
  }
  private addHeadersToRequest(operationModel: OperationModel, request) {
    if (operationModel.security.length) {
      request.addHeader(CodeSamplesFactory.AUTH_HEADER);
    }
    if (operationModel.requestBody) {
      request.addHeader(CodeSamplesFactory.CONTENT_TYPE_HEADER);
    }
    request.addHeader(CodeSamplesFactory.ACCEPT_HEADER);
    const headerParams = this.createParameters(operationModel, 'header');
    headerParams.map(headerElement => {
      request.addHeader(headerElement);
    });
  }
  private createParameters(operationModel: OperationModel, paramType: string) {
    const queryParams = ([] as any);
    for (const parameter of (operationModel.parameters as FieldModel[])) {
      if (parameter.in === paramType && (parameter.example || parameter.required)) {
        const queryParam = new SdkQueryParam.QueryParam({
          key: parameter.name,
          value: parameter.example || `{${parameter.name}}`
        });
        if (parameter.in === 'query') {
          queryParam.value = encodeURIComponent(queryParam.value);
        }
        queryParams.push(queryParam);
      }
    }
    return queryParams;
  }
  private preventDoubleEncoding(codeSnippet: string, params: FieldModel[]) {
    const queryParams = params.filter(param => param.in === 'query' && !!param.example);
    const doubleEncodedQueryParams: string[] = [];
    queryParams.map(param => {
      if (param && param.example) {
        doubleEncodedQueryParams.push(encodeURIComponent(encodeURIComponent(param.example)));
      }
    });
    doubleEncodedQueryParams.map(queryParam => {
      codeSnippet = codeSnippet.replace(queryParam, decodeURIComponent(queryParam));
    });
    return codeSnippet;
  }
}