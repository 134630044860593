import * as React from 'react';
import { AuthHeader, AuthHeaderColumn } from '../SecurityRequirement/SecurityRequirement';
import { OpenAPIXThrottlingInformation } from '../../types';
import { ThrottlingWrapper } from './styled.elements';
import { TimeRateLimit } from './TimeRateLimit';
interface Props {
  throttlingInformation: OpenAPIXThrottlingInformation[];
}
export const ThrottlingInfo = ({
  throttlingInformation
}: Props): JSX.Element | null => {
  const getTimeImplementation = (): OpenAPIXThrottlingInformation | undefined => {
    return throttlingInformation.find(elem => elem.type === 'time');
  };
  const [timeImplementation, setTimeImplementation] = React.useState(getTimeImplementation());
  React.useEffect(() => {
    setTimeImplementation(getTimeImplementation());
  }, [throttlingInformation]);
  return !!timeImplementation ? <ThrottlingWrapper>
      <AuthHeaderColumn>
        <AuthHeader>Request rate limit:</AuthHeader>
      </AuthHeaderColumn>
      <TimeRateLimit timeImplementation={timeImplementation} />
    </ThrottlingWrapper> : null;
};