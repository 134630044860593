import { IMenuItem } from './MenuStore';
import { OperationModel } from './models';
import Worker from './SearchWorker.worker';
const loadSearchWorker = () => {
  try {
    // tslint:disable-next-line:no-implicit-dependencies
    return require('workerize-loader?inline!./SearchWorker.worker');
  } catch (e) {
    return require('./SearchWorker.worker').default;
  }
};
export class SearchStore<T> {
  searchWorker: Worker = new (loadSearchWorker())();
  indexItems(groups: Array<IMenuItem | OperationModel>) {
    const recurse = items => {
      items.forEach(group => {
        if (group.type === 'operation') {
          this.add(group.name, group.description || '', group.id);
        }
        recurse(group.items);
      });
    };
    recurse(groups);
    this.searchWorker.done();
  }
  add(title: string, body: string, meta?: T) {
    this.searchWorker.add(title, body, meta);
  }
  search(q: string) {
    return this.searchWorker.search<T>(q);
  }
  async toJS() {
    return this.searchWorker.toJS();
  }
  load(state: any) {
    this.searchWorker.load(state);
  }
}