import * as React from 'react';
import { FormattedHtmlMessage, __ } from 'ib-i18n';
import { UnderlinedHeader } from '../../common-elements';
import { PropertiesTable } from '../../common-elements/fields-layout';
import { FieldModel } from '../../services/models';
import { Field } from '../Fields/Field';
import { mapWithLast } from '../../utils';
import { QueryParametersWrapper } from './styled.elements';
export interface ParametersGroupProps {
  place: string;
  parameters: FieldModel[];
}
export class ParametersGroup extends React.PureComponent<ParametersGroupProps, any> {
  render() {
    const {
      place,
      parameters
    } = this.props;
    if (!parameters || !parameters.length) {
      return null;
    }
    return <QueryParametersWrapper key={place}>
        <UnderlinedHeader>
          <FormattedHtmlMessage message={__('{place} Parameters')} place={place} />
        </UnderlinedHeader>
        <PropertiesTable>
          <tbody>
            {mapWithLast(parameters, (field, isLast) => <Field key={field.name} isLast={isLast} field={field} showExamples={true} />)}
          </tbody>
        </PropertiesTable>
      </QueryParametersWrapper>;
  }
}