import * as Redirects from '../../../services/Redirects';
export class CustomPagesStore {
  private redirectedCustomPages: Map<string, JSX.Element>;
  constructor(customPages: Map<string, JSX.Element>) {
    this.redirectedCustomPages = redirectCustomPages(customPages);
  }
  getPage(id: string) {
    return this.redirectedCustomPages.get(id);
  }
}
function redirectCustomPages(customPages: Map<string, JSX.Element>) {
  const redirectApplier = Redirects.getRedirectApplier();
  const redirectedPages = new Map();
  customPages.forEach((component, id) => redirectedPages.set(redirectApplier.redirect(id), component));
  return redirectedPages;
}