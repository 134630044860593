export interface CodeSamplesLanguage {
  label: string;
  key: string;
  variant: string;
  options?: object;
}
export interface CodeSampleLanguageProvider {
  getSupportedLanguages(): CodeSamplesLanguage[];
}
export class FixedLanguageProvider implements CodeSampleLanguageProvider {
  static readonly LANGUAGE_CURL = 'curl';
  static readonly LANGUAGE_JAVA = 'java';
  static readonly LANGUAGE_CSHARP = 'csharp';
  static readonly LANGUAGE_GOLANG = 'go';
  static readonly LANGUAGE_PYTHON = 'python';
  static readonly LANGUAGE_PHP = 'php';
  static readonly LANGUAGE_JAVASCRIPT = 'javascript';
  static readonly LANGUAGE_NODEJS = 'nodejs';
  static readonly LANGUAGE_RUBY = 'ruby';
  getSupportedLanguages(): CodeSamplesLanguage[] {
    return [{
      label: 'cURL',
      key: FixedLanguageProvider.LANGUAGE_CURL,
      variant: 'cURL',
      options: {
        multiline: true,
        lineContinuationCharacter: '\\',
        longFormat: false,
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'Java',
      key: FixedLanguageProvider.LANGUAGE_JAVA,
      variant: 'OkHttp',
      options: {
        includeBoilerplate: false,
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'C#',
      key: FixedLanguageProvider.LANGUAGE_CSHARP,
      variant: 'RestSharp',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'Go',
      key: FixedLanguageProvider.LANGUAGE_GOLANG,
      variant: 'Native',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'Python',
      key: FixedLanguageProvider.LANGUAGE_PYTHON,
      variant: 'http.client',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'PHP',
      key: FixedLanguageProvider.LANGUAGE_PHP,
      variant: 'cURL',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'JavaScript',
      key: FixedLanguageProvider.LANGUAGE_JAVASCRIPT,
      variant: 'jQuery',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'NodeJs',
      key: FixedLanguageProvider.LANGUAGE_NODEJS,
      variant: 'Native',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }, {
      label: 'Ruby',
      key: FixedLanguageProvider.LANGUAGE_RUBY,
      variant: 'Net::HTTP',
      options: {
        indentCount: 4,
        indentType: 'Space',
        trimRequestBody: true,
        followRedirect: true
      }
    }];
  }
}