import { darken } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { ShelfIcon } from '../../common-elements';
const HeadingStyle = styled.div.withConfig({
  componentId: "sc-184842m-0"
})(["background-color:", ";color:", ";padding:10px;margin:30px 0 0 0;display:block;transition:0.4s;&:hover{background-color:", ";}"], props => props.theme.menu.backgroundColor, props => props.theme.menu.textColor, props => darken(0.1, props.theme.menu.backgroundColor));
const ContentStyle = styled.div.withConfig({
  componentId: "sc-184842m-1"
})(["padding:10px;"]);
export interface AccordionProps {
  title?: string;
  children: React.ReactNode;
}
export class Accordion extends React.Component<AccordionProps> {
  state = {
    opened: false
  };
  onClickHandler = event => {
    event.preventDefault();
    this.setState(() => {
      return {
        opened: !this.state.opened
      };
    });
  };
  render() {
    return <>
        <HeadingStyle onClick={this.onClickHandler}>
          {this.props.title}
          <ShelfIcon direction={this.state.opened ? 'down' : 'right'} float={'right'} style={{
          marginTop: '3px'
        }} />
        </HeadingStyle>

        {this.state.opened && <ContentStyle>{this.props.children}</ContentStyle>}
      </>;
  }
}