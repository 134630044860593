import React from 'react';
import { __ } from 'ib-i18n';
import { AdditionalVersioningInfoBadgeLatest, AdditionalVersioningInfoBadge, AdditionalVersioningInfo } from './styled.elements';
export const AdditionalVersioning = ({
  latest,
  versionNumber
}) => {
  const InfoBadge = latest ? AdditionalVersioningInfoBadgeLatest : AdditionalVersioningInfoBadge;
  return <AdditionalVersioningInfo>
      {latest ? __('Latest API') : __('API')}
      <InfoBadge>{'V' + versionNumber}</InfoBadge>
    </AdditionalVersioningInfo>;
};