import * as React from 'react';
import { observer } from 'mobx-react';
import { AppStore, MiddlePanel } from '../../..';
import { StyledDiv, StyledP } from '../../NavBar/styled.elements';
import { StyledLandingPageHeader, StyledUl, TitleSection } from '../HomePage/HomePageComponents';
import { H1 } from '../../../common-elements/headers';
import { FormattedHtmlMessage, __ } from 'ib-i18n';
@observer
export class WhatsAppGetStarted extends React.Component<{
  store: AppStore;
}> {
  render() {
    const {
      docsUrl,
      portalUrl
    } = this.props.store.options;
    return <MiddlePanel compact={true} style={{
      width: '80%'
    }}>
        <H1>{__('Get Started with WhatsApp')}</H1>
        <StyledP>
          {/* prettier-ignore */
        __('Send and receive simple messages, preset buttons, or rich media message by using our extensive WhatsApp API.')}
          <br />
          {/* prettier-ignore */
        __('Getting started is easy. Even in trial, you can experience WhatsApp two-way communication with ease and put yourself in your customer\'s shoes.')}
          <br />
          <br />
          <FormattedHtmlMessage message={__('To utilize WhatsApp in combination with other channels, check out {messagesApi}.')} messagesApi={<a href={`${docsUrl}/api/platform/messages-api`}>{__('Messages API')}</a>} />
        </StyledP>
        <TitleSection id="send-your-first-message">{__('Send your first message!')}</TitleSection>
        <StyledDiv>
          <StyledLandingPageHeader>
            {__('1. Configure your webhook (optional)')}
          </StyledLandingPageHeader>
          <StyledDiv>
            {/* prettier-ignore */
          __('Once a message is sent by your customer, we will deliver it to you in real time. For this, you need to provide your HTTP endpoint. You can continue this guide without completing this step, just remember to do it later.')}
            <StyledP>
              <a href={`${portalUrl}/apps/whatsapp/senders/1AD64938DE25A3818114CD5E15DB817E/config/447860099299?isShared=true`}>
                {__('Configure your webhook here')}
              </a>
            </StyledP>
          </StyledDiv>

          <StyledLandingPageHeader>{__('2. Activate your test sender')}</StyledLandingPageHeader>
          <StyledDiv>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td>
                    <StyledDiv>
                      <FormattedHtmlMessage message={/* prettier-ignore */
                    __('Use your phone camera to scan the code, or add <b>447860099299</b> Infobip sender to your WhatsApp contacts. In the conversation thread enter your <b>Infobip account username</b> and hit <b>Send</b>. You will immediately receive an auto-reply message confirming sender activation.')} />
                    </StyledDiv>
                  </td>
                  <td style={{
                  verticalAlign: 'bottom',
                  paddingLeft: '10px'
                }}>
                    <img style={{
                    width: '140px',
                    height: '140px',
                    display: 'block',
                    margin: 'auto'
                  }} src="https://dpyvpfucsmjf7.cloudfront.net/redoc/assets/images/whatsapp-qr-getstarted.png" />
                  </td>
                </tr>
              </tbody>
            </table>
          </StyledDiv>

          <StyledLandingPageHeader>{__('3. Send a WhatsApp message')}</StyledLandingPageHeader>
          <StyledDiv>
            {/* prettier-ignore */
          __('If you are initiating a communication with your audience, you must start with a templated message which was pre-approved by WhatsApp. Use it to send a message to one or many recipients, send notifications, or start a dialog.')}
            <br />
          </StyledDiv>
        </StyledDiv>
        <StyledP>
          <FormattedHtmlMessage message={__('For more information on usage and code examples explore {whatsappApiReference}.')} whatsappApiReference={<a href={`${docsUrl}/api#channels/whatsapp/send-whatsapp-template-message`}>
                {__('Send fully featured WhatsApp API reference')}
              </a>} />
        </StyledP>

        <StyledLandingPageHeader>{__('Continue a conversation')}</StyledLandingPageHeader>
        <StyledDiv>
          {__('There are 2 ways to start a conversation:')}
          <StyledUl style={{
          listStyleType: 'decimal'
        }}>
            <li>{__('Your customer sends the initial message to you.')}</li>
            <li>{__('Your customer responds to your templated message.')}</li>
          </StyledUl>
          {/* prettier-ignore */
        __('Once the conversation starts, there is a 24-hour window for sending any message content. Use different message building blocks, like text, media, buttons, etc.')}
          <br />
          <br />
          {/* prettier-ignore */
        __('See all API building blocks in the navigation menu on the left. Use provided code examples to test them.')}
        </StyledDiv>

        <TitleSection>{__('Next steps to explore')}</TitleSection>
        <StyledUl>
          <li>
            <a href={`${docsUrl}/sdk`}>{__('Get API client library')}</a>
          </li>
          <li>
            <a href={`${portalUrl}/payments`}>{__('Upgrade account')}</a>
          </li>
          <li>
            <a href={`${docsUrl}/whatsapp/get-started`}>{__('Sender registration overview')}</a>
          </li>
          <li>
            <a href={`${docsUrl}/whatsapp/message-types`}>{__('Explore WhatsApp message types')}</a>
          </li>
        </StyledUl>
        <TitleSection>{__('Need help?')}</TitleSection>
        <StyledP>
          <FormattedHtmlMessage message={__('In case you get stuck at any point, visit {getStarted} to find any information you need to successfully use Infobip API.')} getStarted={<a href={`${docsUrl}/api`}>{__('API Get started page')}</a>} />
        </StyledP>
      </MiddlePanel>;
  }
  triggerGtm(e) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'buttonClick',
      buttonLocation: e.target.parentNode.parentNode.getAttribute('id'),
      buttontext: e.target.innerHTML
    });
    return false;
  }
}