import * as React from 'react';
import { CategoryMenuItem, ProductMenuItem } from '../../services';
import { MenuItemLabel, MenuItemTitle } from './styled.elements';
import { observer } from 'mobx-react';
export interface MenuGroupProps {
  item: CategoryMenuItem | ProductMenuItem;
  children?: React.ReactNode;
}
@observer
export class MenuGroup extends React.Component<MenuGroupProps> {
  ref = React.createRef<HTMLLabelElement>();
  componentDidUpdate() {
    if (this.props.item.active && this.ref.current) {
      this.ref.current.scrollIntoView({
        block: 'nearest'
      });
    }
  }
  render() {
    const {
      item
    } = this.props;
    return <MenuItemLabel depth={item.depth} type={item.type} active={item.active} cssDisplay={'flex'} expanded={item.expanded} ref={this.ref}>
        <MenuItemTitle title={item.name}>
          {item.name}
          {this.props.children}
        </MenuItemTitle>
      </MenuItemLabel>;
  }
}