// tslint:disable-next-line:no-implicit-dependencies
import { toArray } from 'lodash';
import * as React from 'react';
import { __ } from 'ib-i18n';
import { ResponseModel } from '../../services';
import { ReqResSchemaHeader, ReqResSchemaTypeChooser } from '../../common-elements';
import { DropdownOrLabel } from '../DropdownOrLabel/DropdownOrLabel';
import { MediaTypesSwitch } from '../MediaTypeSwitch/MediaTypesSwitch';
import { Schema } from '../Schema';
import { Markdown } from '../Markdown/Markdown';
import { ResponseHeaders } from './ResponseHeaders';
import { ExpandCollapse } from '../ExpandCollapse/ExpandCollapse';
import { StoreConsumer } from '..';
import { AppStore } from '../..';
import { AuthHeader } from '../SecurityRequirement/SecurityRequirement';
export class ResponseDetails extends React.PureComponent<{
  response: ResponseModel;
}> {
  render() {
    const {
      description,
      headers,
      content,
      code
    } = this.props.response;
    return <StoreConsumer>
        {store => <>
            {description && <Markdown source={description} />}
            <ResponseHeaders headers={headers} />
            <MediaTypesSwitch content={content}
        // tslint:disable-next-line
        renderDropdown={props => this.renderDropdown(props, content)} onExpand={this.expand.bind(this, code, store)} onCollapse={this.collapse.bind(this, code, store)}>
              {({
            schema
          }) => {
            return <Schema skipWriteOnly={true} key="schema" schema={schema} />;
          }}
            </MediaTypesSwitch>
          </>}
      </StoreConsumer>;
  }
  collapse = (responseCode: string, store?: AppStore) => {
    store?.collapseFieldsAndSamples(responseCode);
  };
  expand = (responseCode: string, store?: AppStore) => {
    store?.expandFieldsAndSamples(responseCode);
  };
  private renderDropdown = (props, content) => {
    const checkIfContentIsExpanded = (): boolean => {
      const examples = toArray(content.mediaTypes[content.mediaTypes.findIndex(obj => obj.name === props.value.label)].examples);
      let isExpanded = true;
      examples.forEach(example => {
        if (!example.expanded) {
          isExpanded = false;
        }
      });
      return isExpanded;
    };
    return <ReqResSchemaHeader key="header">
        <ReqResSchemaTypeChooser>
          <AuthHeader>{`${__('Response Schema')}: `}</AuthHeader>
          <DropdownOrLabel {...props} />
        </ReqResSchemaTypeChooser>
        <ExpandCollapse onCollapse={props.onCollapse} onExpand={props.onExpand} expanded={checkIfContentIsExpanded()} />
      </ReqResSchemaHeader>;
  };
}