import styled from '../../styled-components';
import { Link } from '../Redoc/HomePage/HomePageComponents';
export const ThrottlingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const TimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-right: 10px;
  }

  @media only screen and (max-width: 767px) {
    margin: 10px 0 10px 0;
    flex-direction: column;
  }
`;
export const ThrottlingLearnMore = styled(Link)`
  @media only screen and (max-width: 767px) {
    margin-top: 5px;
    &:after {
      content: ' about requests';
    }
  }
`;