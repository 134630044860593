import { SearchEvent } from './models/SearchEvent';
import { IS_BROWSER } from '../utils';
export interface SearchEventProcessor {
  process(searchEvent: SearchEvent): void;
}
export class ConsoleSearchEventProcessor implements SearchEventProcessor {
  private static readonly LENGTH_THRESHOLD: number = 2;
  process(searchEvent: SearchEvent): void {
    if (this.shouldProcessForTerm(searchEvent.getSearchTerm())) {
      console.log(searchEvent.asSearchTrace());
    }
  }
  private shouldProcessForTerm(searchTerm: string): boolean {
    return searchTerm.length > ConsoleSearchEventProcessor.LENGTH_THRESHOLD;
  }
}
export class GoogleTagManagerSearchEventProcessor implements SearchEventProcessor {
  private static readonly LENGTH_THRESHOLD: number = 2;
  process(searchEvent: SearchEvent): void {
    if (IS_BROWSER && (window as any).dataLayer) {
      if (this.shouldProcessForTerm(searchEvent.getSearchTerm())) {
        const data = searchEvent.asSearchTrace();
        (window as any).dataLayer.push(data);
      }
    }
  }
  private shouldProcessForTerm(searchTerm: string): boolean {
    return searchTerm.length > GoogleTagManagerSearchEventProcessor.LENGTH_THRESHOLD;
  }
}