import * as React from 'react';
import styled from 'styled-components';
import { OptionsConsumer } from '../OptionsProvider';
const StyledSurvey = styled.div.withConfig({
  componentId: "sc-1h8g37l-0"
})(["padding-top:20px;margin-bottom:64px;"]);
const StyledIFrame = styled.iframe.withConfig({
  componentId: "sc-1h8g37l-1"
})(["width:400px;height:410px;border:none;"]);
interface SurveyState {
  currentUrl: string;
}
class Survey extends React.Component<{}, SurveyState> {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.href
    };
  }
  componentDidMount(): void {
    this.setState({
      currentUrl: window.location.href
    });
  }
  buildUrl = (options, currentUrl) => {
    return 'https://www.surveymonkey.com/r/' + options.surveyMonkeyId + '?url=' + encodeURIComponent(currentUrl);
  };
  render() {
    return <OptionsConsumer>
        {options => options.surveyMonkeyEnabled && <StyledSurvey>
              <StyledIFrame src={this.buildUrl(options, this.state.currentUrl)} />
            </StyledSurvey>}
      </OptionsConsumer>;
  }
}
export default Survey;