import { action, makeObservable, observable } from 'mobx';
import { ContentItemModel, MenuItemType } from '..';
import { OpenAPIExternalDocumentation, OpenAPITag } from '../../types';
import { safeSlugify } from '../../utils';
import { MarkdownHeading } from '../MarkdownRenderer';
import { IMenuItem } from '../MenuStore';
export abstract class ExpandableItem {
  parent?: ContentItemModel;
  @observable
  expanded: boolean = false;
  constructor(parent?: ContentItemModel) {
    makeObservable(this);
    this.parent = parent;
  }
  @action
  expand() {
    if (this.parent) {
      this.parent.expand();
    }
    this.expanded = true;
  }
  @action
  collapse() {
    this.expanded = false;
  }
}
export abstract class ActivateableItem extends ExpandableItem {
  @observable
  active: boolean = false;
  hasActiveChild: boolean = false;
  constructor(parent?: ContentItemModel) {
    super(parent);
    makeObservable(this);
  }
  @action
  activate() {
    this.active = true;
    this.parent?.registerChildActivation();
  }
  @action
  deactivate() {
    this.active = false;
    this.parent?.registerChildDeactivation();
  }
  private registerChildActivation(): void {
    this.hasActiveChild = true;
    this.parent?.registerChildActivation();
  }
  private registerChildDeactivation(): void {
    this.hasActiveChild = false;
    this.parent?.registerChildDeactivation();
  }
}
export class SectionMenuItem extends ActivateableItem implements IMenuItem {
  active = false;
  expanded = true;
  type = ('section' as 'section');
  depth = 0;
  level = 1;
  id: string;
  absoluteIdx?: number;
  name: string;
  summary: string;
  description: string;
  externalDocs?: OpenAPIExternalDocumentation;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel;
  childrenType?: MenuItemType;
  deprecated?: boolean | undefined;
  constructor(tag: OpenAPITag, parent?: ContentItemModel) {
    super(parent);
    this.id = `tag/${safeSlugify(tag.name)}`;
    this.name = tag['x-displayName'] || tag.name;
    this.summary = this.name;
    this.description = tag.description || '';
    this.externalDocs = tag.externalDocs;
    this.parentProduct = parent?.parentProduct;
  }
  deactivate(): void {
    // ignore
  }
  activate(): void {
    // ignore
  }
  collapse(): void {
    // ignore
  }
  expand(): void {
    if (this.parent) {
      this.parent.expand();
    }
  }
}
function formatId(item: string) {
  return item.replace(/ /g, '-').toLowerCase();
}
function generateId(tag: OpenAPITag, parent?: ContentItemModel): string {
  const path = parent ? parent.id + '/' : '';
  return formatId(path + tag.name);
}
export class CategoryMenuItem extends ActivateableItem implements IMenuItem {
  type = ('category' as 'category');
  depth = 1;
  level = 1;
  externalDocs = undefined;
  expanded = true;
  id: string;
  absoluteIdx?: number;
  name: string;
  description: string;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel = undefined;
  childrenType?: MenuItemType;
  constructor(tag: OpenAPITag) {
    super(undefined);
    this.id = formatId(tag.name);
    this.name = tag['x-displayName'] || tag.name;
    this.description = tag.description || '';
  }
  collapse(): void {
    // ignore
  }
  expand(): void {
    // ignore
  }
}
export class PageMenuItem extends ActivateableItem implements IMenuItem {
  type = ('page' as 'page');
  depth = 1;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel = undefined;
  childrenType?: MenuItemType;
  externalDocs = undefined;
  id: string;
  absoluteIdx?: number;
  name: string;
  description?: string;
  level: number;
  constructor(heading: MarkdownHeading) {
    super(undefined);
    this.id = heading.id;
    this.name = heading.name;
    this.level = heading.level;

    // remove sections from markdown, same as in ApiInfo
    this.description = heading.description || '';
    const firstHeadingLinePos = this.description.search(/^##?\s+/m);
    if (firstHeadingLinePos > -1) {
      this.description = this.description.substring(0, firstHeadingLinePos);
    }
  }
}
export class ProductMenuItem extends ActivateableItem implements IMenuItem {
  type = ('product' as 'product');
  depth = 2;
  level = 1;
  externalDocs = undefined;
  id: string;
  absoluteIdx?: number;
  name: string;
  description: string;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel;
  childrenType?: MenuItemType;
  constructor(tag: OpenAPITag, parent?: ContentItemModel) {
    super(parent);
    this.id = generateId(tag, parent);
    this.name = tag['x-displayName'] || tag.name;
    this.description = tag.description || '';
    this.depth = parent ? parent.depth + 1 : 2;
    this.parentProduct = this;
  }
  expand() {
    super.expand();
    this.items.forEach(childItem => {
      if (childItem.type === 'module' && childItem.childrenType === 'submodule') {
        childItem.expanded = true;
      }
    });
  }
  collapse(): void {
    if (this.active || this.hasActiveChild) {
      return;
    }
    super.collapse();
  }
}
export class ModuleMenuItem extends ActivateableItem implements IMenuItem {
  type = ('module' as 'module');
  depth = 3;
  level = 1;
  externalDocs = undefined;
  id: string;
  absoluteIdx?: number;
  name: string;
  description: string;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel;
  childrenType?: MenuItemType;
  constructor(tag: OpenAPITag, parent?: ContentItemModel) {
    super(parent);
    this.id = generateId(tag, parent);
    this.name = tag['x-displayName'] || tag.name;
    this.description = tag.description || '';
    this.depth = parent ? parent.depth + 1 : 3;
    this.parentProduct = parent?.parentProduct;
  }
  collapse(): void {
    if (this.childrenType === 'submodule') {
      return;
    }
    super.collapse();
  }
}
export class SubmoduleMenuItem extends ActivateableItem implements IMenuItem {
  type = ('submodule' as 'submodule');
  depth = 4;
  level = 1;
  externalDocs = undefined;
  id: string;
  absoluteIdx?: number;
  name: string;
  description: string;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel;
  childrenType?: MenuItemType;
  constructor(tag: OpenAPITag, parent?: ContentItemModel) {
    super(parent);
    this.id = generateId(tag, parent);
    this.name = tag['x-displayName'] || tag.name;
    this.description = tag.description || '';
    this.depth = parent ? parent.depth + 1 : 4;
    this.parentProduct = parent?.parentProduct;
  }
}
export type GroupModel = SectionMenuItem | CategoryMenuItem | PageMenuItem | ProductMenuItem | ModuleMenuItem | SubmoduleMenuItem;