import { observer } from 'mobx-react';
import * as React from 'react';
import logo from '../../images/infobip-logo.svg';
import { __ } from 'ib-i18n';
import { AppStore } from '../../services';
import { LogoContainer, MainNav, NavBarLi, NavBarUl, NavWrapper, ScrollTransition, StyledLink, UnstyledLink, HamburgerMenu, MenuButton, MenuSidebar, MenuItem, PrimaryLinkButton, LogoSvg } from './styled.elements';
import { NavBarLinksE, ResolvedThemeInterface } from '../../theme';
interface NavBarProps {
  store: AppStore;
  scrollEnabled: boolean;
  docsUrl: string;
  logoUrl?: string;
  themeName?: string;
}
interface DocsLink {
  id: NavBarLinksE;
  label: string;
  url: string;
  active: boolean;
  className?: string;
  target?: '_blank' | '_self';
}
class DocsLinksProvider {
  constructor(private readonly docsUrl: string, private readonly theme: ResolvedThemeInterface) {
    this.docsUrl = docsUrl;
    this.theme = theme;
  }
  docsLinks(): DocsLink[] {
    let links: DocsLink[] = [{
      id: NavBarLinksE.PRODUCTS,
      label: __('Product Documentation'),
      url: this.buildDocsUrl('/product-documentation'),
      active: false
    }, {
      id: NavBarLinksE.ESSENTIALS,
      label: __('Essentials'),
      url: this.buildDocsUrl('/essentials'),
      active: false
    }, {
      id: NavBarLinksE.TUTORIALS,
      label: __('Tutorials'),
      url: this.buildDocsUrl('/tutorials'),
      active: false
    }, {
      id: NavBarLinksE.RELEASE_NOTES,
      label: __('Release Notes'),
      url: this.buildDocsUrl('/release-notes'),
      active: false
    }, {
      id: NavBarLinksE.PUBLIC_ROADMAP,
      label: 'Public Roadmap',
      url: 'https://roadmap.infobip.com',
      target: '_blank',
      active: false
    }, {
      id: NavBarLinksE.INTEGRATIONS,
      label: __('Integrations'),
      url: this.buildDocsUrl('/integrations'),
      active: false
    }, {
      id: NavBarLinksE.API,
      label: __('API'),
      url: window.location.origin + '/docs/api',
      active: true
    }, {
      id: NavBarLinksE.SDKS,
      label: __('SDKs'),
      url: this.buildDocsUrl('/sdk'),
      active: false
    }];
    if (this.theme.navbar?.links) {
      links = links.filter(link => this.theme.navbar.links.includes(link.id));
    }
    return links;
  }
  private buildDocsUrl = (path: string): string => `${this.docsUrl}${path}`;
}
interface NavBarState {
  currentUrl: string;
  prevScrollPos: number;
  show: boolean;
  themeName?: string;
}
@observer
export class NavBar extends React.Component<NavBarProps, NavBarState> {
  private readonly docsLinksProvider: DocsLinksProvider;
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.href,
      prevScrollPos: window.scrollY,
      show: true,
      themeName: props.themeName
    };
    this.docsLinksProvider = new DocsLinksProvider(this.props.docsUrl, this.props.store.options.theme);
  }
  componentDidMount() {
    if (this.props.scrollEnabled) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount() {
    if (this.props.scrollEnabled) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const show = this.state.prevScrollPos > currentScrollPos;
    this.setState({
      prevScrollPos: currentScrollPos,
      show
    });
  };
  handleLoginHover = () => {
    this.setState({
      currentUrl: window.location.href.replace(/^http:\/\//i, 'https://')
    });
  };
  handleLogoutOnClick = () => {
    this.props.store.logoutUser();
  };
  render() {
    const {
      signupUrl
    } = this.props.store.options;
    const user = this.props.store.user;
    const loginUrl = this.props.store.options.loginUrl;
    return <ScrollTransition>
        <NavWrapper className="navbar">
          <MainNav>
            <LogoContainer href={window.location.origin}>
              <LogoSvg src={this.props.logoUrl || logo} title="Infobip Logo" className="logo" />
            </LogoContainer>

            <NavBarUl>
              {this.docsLinksProvider.docsLinks().map((docsLink, index) => <NavBarLi key={`docs-link-${index}`} className={docsLink.active ? 'active' : docsLink.className || ''}>
                  <UnstyledLink href={docsLink.url} target={docsLink.target}>
                    {docsLink.label}
                  </UnstyledLink>
                </NavBarLi>)}
            </NavBarUl>

            {user.loggedIn ? <div className="navbar-logout">
                <StyledLink onClick={this.handleLogoutOnClick}>{user.userName}</StyledLink>
                <StyledLink onClick={this.handleLogoutOnClick}>Log out</StyledLink>
              </div> : <>
                <StyledLink onMouseEnter={this.handleLoginHover} href={loginUrl.concat(this.state.currentUrl)} className="navbar-login">
                  {__('Log in')}
                </StyledLink>
                <PrimaryLinkButton href={signupUrl}>{__('Try for free')}</PrimaryLinkButton>
              </>}
            <HamburgerMenu>
              <input id="hamburger" type="checkbox" />
              <MenuButton className="menu__btn" htmlFor="hamburger">
                <span />
              </MenuButton>

              <MenuSidebar className="menu-sidebar">
                {this.docsLinksProvider.docsLinks().map((docsLink, index) => <MenuItem key={`docs-link-${index}`}>
                    <UnstyledLink href={docsLink.url}>{docsLink.label}</UnstyledLink>
                  </MenuItem>)}
              </MenuSidebar>
            </HamburgerMenu>
          </MainNav>
        </NavWrapper>
      </ScrollTransition>;
  }
}