import * as React from 'react';
import { __ } from 'ib-i18n';
import { CodeSampleModel } from '../../services/CodeSamplesFactory';
import { DropdownOption } from '../../common-elements';
import { DropdownWrapper, DropdownLabel, InvertedSimpleDropdown, MimeLabel } from '../PayloadSamples/styled.elements';
import { DropdownOrLabel } from '../DropdownOrLabel/DropdownOrLabel';
import { SourceCodeWithCopy } from '../SourceCode/SourceCode';
export class CodeSample extends React.Component<{
  sample: CodeSampleModel;
}, {
  active: {
    [key: string]: number;
  };
}> {
  state = {
    active: {}
  };
  activateExample(lang: string, idx: string) {
    this.setState(state => {
      const langToActiveSource = {
        ...state.active
      };
      langToActiveSource[lang] = parseInt(idx, 10);
      return {
        ...state,
        active: langToActiveSource
      };
    });
  }
  render() {
    const {
      sample
    } = this.props;
    const activeSourceIdx = this.state.active[sample.lang] || 0;
    const activeSource = sample.sources[activeSourceIdx];
    const options = sample.sources.map((source, idx) => ({
      label: source.name,
      value: `${idx}`
    }));
    const activeValue = {
      label: activeSource.name,
      value: `${activeSourceIdx}`
    };
    const onChange = (val: DropdownOption) => this.activateExample(sample.lang, val.value);
    return <div>
        <DropdownWrapper>
          <DropdownLabel>{__('Example')}</DropdownLabel>
          <DropdownOrLabel value={activeValue} options={options} onChange={onChange} Dropdown={((InvertedSimpleDropdown as unknown) as React.ComponentClass<{}, any>)} Label={((MimeLabel as unknown) as React.ComponentClass<{}, any>)} />
        </DropdownWrapper>
        <SourceCodeWithCopy lang={sample.lang} source={activeSource.source} />
      </div>;
  }
}