import * as React from 'react';
import { IS_BROWSER } from '../../utils/';
import { StoreConsumer } from '../StoreBuilder';
export interface LoginLinkProps {
  label: string;
}
export class LoginLink extends React.Component<LoginLinkProps> {
  static defaultProps = {
    label: 'log in'
  };
  activePath(): string {
    const loc = IS_BROWSER ? window.parent.location : null;
    return loc && `${loc.protocol}//${loc.host}${loc.pathname}` || '';
  }
  render() {
    const {
      label
    } = this.props;
    const activePath = this.activePath();
    return <StoreConsumer>
        {store => {
        const {
          loginUrl
        } = store && store.options || {};
        const {
          activeItem
        } = store && store.menu || {};
        const loginUrlWithCallback = `${loginUrl}${activePath}#${activeItem && activeItem.id || ''}`;
        return <a href={loginUrlWithCallback}> {label} </a>;
      }}
      </StoreConsumer>;
  }
}