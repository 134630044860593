import * as React from 'react';
import styled from '../styled-components';
const directionMap = {
  left: '90deg',
  right: '-90deg',
  up: '-180deg',
  down: '0'
};
class IntShelfIcon extends React.PureComponent<{
  className?: string;
  float?: 'left' | 'right';
  size?: string;
  color?: string;
  direction: 'left' | 'right' | 'up' | 'down';
  style?: React.CSSProperties;
}> {
  render() {
    return <svg className={this.props.className} style={this.props.style} version="1.1" viewBox="0 0 24 24" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
        <polygon points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 " />
      </svg>;
  }
}
class IntHomeIcon extends React.PureComponent<{
  className?: string;
  color?: string;
  size?: string;
}> {
  render() {
    return <svg className={this.props.className} width="16" height="16" viewBox="0 0 16 16" fill={this.props.color} xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M1.50972 8.29078C1.31047 8.26596 1.11123 8.26596 0.936886 8.21631C0.0153683 7.96809 -0.308408 6.82624 0.339145 6.13121C0.388957 6.08156 0.413862 6.05674 0.463674 6.00709C2.33162 4.14539 4.17465 2.30851 6.01769 0.446808C6.61543 -0.148936 7.38751 -0.148936 7.98525 0.446808C9.85319 2.30851 11.7211 4.17021 13.5891 6.03192C14.0872 6.52837 14.137 7.2234 13.7136 7.7695C13.4645 8.0922 13.1408 8.24113 12.7423 8.26596C12.6676 8.26596 12.5928 8.26596 12.4932 8.26596C12.4932 8.34043 12.4932 8.39007 12.4932 8.43972C12.4932 9.78014 12.4932 11.1206 12.4932 12.461C12.4932 13.1809 12.02 13.7518 11.3226 13.9255C11.1981 13.9504 11.0736 13.9752 10.9491 13.9752C10.2268 13.9752 9.52942 13.9752 8.80715 13.9752C8.48337 13.9752 8.33393 13.8262 8.33393 13.5035C8.33393 12.461 8.33393 11.4184 8.33393 10.3759C8.33393 9.9539 8.03506 9.58156 7.56185 9.60638C7.16336 9.60638 6.78977 9.60638 6.39128 9.60638C5.91806 9.60638 5.6441 9.9539 5.6441 10.3511C5.6441 11.3936 5.6441 12.4362 5.6441 13.4787C5.6441 13.7766 5.46976 14 5.12107 14C4.42371 14 3.72635 14 3.02898 14C2.13237 14 1.48482 13.3546 1.48482 12.461C1.48482 11.1454 1.48482 9.80496 1.48482 8.48936V8.34043L1.50972 8.29078Z" fill="#FC6423" />
      </svg>;
  }
}
export const HomeIcon = styled(IntHomeIcon)`
  path {
    fill: ${props => props.color && props.theme.colors[props.color] && props.theme.colors[props.color].main || props.color};
  }

  path:last-of-type {
    fill: ${props => props.theme.colors.primary.main};
  }
`;
export const ShelfIcon = styled(IntShelfIcon)`
  order: -1;
  width: ${props => props.size || '18px'};
  height: ${props => props.size || '18px'}
  vertical-align: middle;
  float: ${props => props.float || ''};
  transition: transform 0.2s ease-out;
  transform: rotateZ(${props => directionMap[props.direction || 'down']});

  polygon {
    fill: ${props => props.color && props.theme.colors[props.color] && props.theme.colors[props.color].main || props.color};
  }
`;
export const Badge = styled.span<{
  type: string;
}>`
  display: inline-block;
  padding: 0 5px;
  margin: 0 2px;
  border-radius: 5px;
  line-height: 1.5em;
  background-color: ${props => props.theme.colors[props.type].main};
  color: ${props => props.theme.colors[props.type].contrastText};
  font-size: ${props => props.theme.typography.code.fontSize};
  vertical-align: middle;
`;