import styled, { media } from '../../../styled-components';
import { StyledP } from '../../NavBar/styled.elements';
export const TitleSection = styled.h2`
  margin-top: 50px;
  margin-bottom: 1.5rem;
  font-family: Inter, sans-serif;
  font-size: 1.75em;
  line-height: 1.6em;

  &.is-active-link {
    color: ${props => props.theme.navBar.linkColorActive};
  }
`;
export const HR = styled.hr`
  height: 1px;
  border: none;
  margin: 40px 0;
  background-color: #e6e3e3;
`;
export const StyledPMedium = styled((StyledP as any))`
  line-height: 26px;

  ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 424px) {
      padding-left: 10px;
    }

    li {
      margin-left: 20px;
      line-height: 26px;

      a {
        color: ${props => props.theme.colors.primary.main};
        text-decoration: none;

        &:hover {
          color: #ff9c66;
        }
      }
    }
  }
`;
export const StyledUl = styled.ul`
  list-style-type: disc;
  padding-left: 30px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 424px) {
    padding-left: 10px;
  }

  li {
    margin-left: 20px;
    line-height: 26px;

    a {
      color: ${props => props.theme.colors.primary.main};
      text-decoration: none;

      &:hover {
        color: #ff9c66;
      }
    }
  }
`;
export const StyledDivMediumBox = styled.div`
  a {
    text-decoration: none;
    color: ${props => props.theme.typography.links.color};

    &:visited {
      color: ${props => props.theme.typography.links.visited};
    }

    &:hover {
      color: ${props => props.theme.typography.links.hover};
    }
  }
`;
export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.typography.links.color};

  &:visited {
    color: ${props => props.theme.typography.links.visited};
  }

  &:hover {
    color: ${props => props.theme.typography.links.hover};
  }
`;
export const LinkExt = styled((Link as any))`
  padding-right: 17px;
  background-position: right 46%;
  background-repeat: no-repeat;
  background-size: 11px;
  background-image: url('https://www.infobip.com/docs/assets/img/devweb/external-link-orange.svg');
`;
export const StyledUlNav = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  jusityf-content: space-between;
  padding-left: 0;
  border: 1px solid rgba(66, 66, 66, 0.2);
  border-radius: 4px;
  margin: 50px 0;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);

  @media (max-width: 1299px) {
    display: inline-flex;
    flex-direction: column;
    padding: 0 30px;
    margin: 10px 0;
  }

  &.nav-email {
    @media (max-width: 1899px) {
      display: inline-flex;
      flex-direction: column;
      padding: 0 30px;
      margin: 10px 0;

      li {
        text-align: left;

        &:not(:last-of-type)::after {
          top: 100%;
          right: auto;
          left: 0;
          width: 50%;
          height: 1px;
          transform: translate(0, 0);
        }
      }
    }
  }

  li {
    flex: 1 1 auto;
    text-align: center;

    @media (max-width: 1299px) {
      text-align: left;
    }

    &:not(:last-of-type) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        width: 1px;
        height: 50%;
        transform: translateY(-50%);
        background-color: rgba(66, 66, 66, 0.2);

        @media (max-width: 1299px) {
          top: 100%;
          right: auto;
          left: 0;
          width: 50%;
          height: 1px;
          transform: translate(0, 0);
        }
      }
    }

    a {
      display: inline-block;
      padding: 20px 17px 20px 0;
      font-size: 15px;
      font-weight: 700;
    }

    a.no-ext {
      padding-right: 0;
    }
  }
`;
export const CodeExampleList = styled.ul`
  list-style-type: none;
  display: flex;
  jusityf-content: flex-start;
  flex-wrap: wrap;
  padding-left: 0;

  li {
    flex: 0 1 auto;
    margin-right: 15px;
    text-align: center;

    a {
      display: inline-block;
      padding: 10px 45px 10px 30px;
      border: 2px solid rgba(66, 66, 66, 0.1);
      border-radius: 4px;
      background-color: #ffffff;
      background-position: calc(100% - 30px) center;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
      font-size: 18px;
      font-weight: 700;

      &:hover {
        border-color: ${props => props.theme.colors.primary.main};
        color: ${props => props.theme.colors.primary.main};
      }
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }
`;
export const HomeWrapper = styled.div`
  display: flex;
  gap: 11rem;
`;
export const PageContent = styled.div`
  width: 100%;
`;
export const CodeExampleLink = styled.a`
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid rgba(66, 66, 66, 0.2);
  border-radius: 4px;
  margin: 50px 0;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
`;
export const PageContentList = styled.div`
  position: sticky;
  top: 120px;
  left: calc(100% - 500px);
  height: 100%;
  max-height: calc(100vh - 180px);

  @media (max-width: 95rem) {
    left: calc(100% - 400px);
  }

  ${media.lessThan('medium', true)`
    display: none;
  `};

  p {
    color: #131336;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 20px;
    border-left: 1px solid #d0dcf2;
  }

  ul {
    min-width: 300px;
    padding: 0 0 36px 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 18px;

      a {
        display: inline-block;
        color: #131336;
        font-size: 14px;
        line-height: 21px;
        text-decoration: none;
        padding: 8px 20px;
        border-radius: 8px;

        &:hover {
          color: #0f5583;
        }

        &:active {
          background: #bdd1de;
        }
      }

      &.is-active {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: -1px;
          width: 4px;
          height: 30px;
          background-color: ${props => props.theme.navBar.linkBGColorActive};
          border-radius: 20px;
        }

        a {
          color: ${props => props.theme.navBar.linkColorActive};
          background-color: ${props => props.theme.navBar.linkBGColorActive};
          font-weight: 700;
        }
      }
    }
  }
`;
export const BoxRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  [data-input-border] {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hidden {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .box-info {
    display: inline-flex;
    flex: 1 1 auto;

    strong {
      padding-right: 5px;
    }

    input#apiKey {
      width: 100%;
    }
  }

  .box-tools {
    display: inline-flex;
    align-items: center;
  }

  svg.eye-hidden {
    display: none;
  }

  &.shown {
    .box-info strong {
      flex: 1 0 auto;
    }

    svg.eye-visible {
      display: none;
    }

    svg.eye-hidden {
      display: inline-block;
    }
  }

  i {
    display: inline-block;
    line-height: 1;
    padding: 3px;
    margin: 0 3px;

    svg {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .link {
    position: relative;
    width: 126px;
    padding-left: 15px;
    margin-left: 8px;

    &::before {
      content: '';
      position: absolute;
      top: -3px;
      left: 0;
      width: 1px;
      height: 30px;
      background-color: #979797;
    }
  }

  .external {
    padding-right: 17px;
    background-position: right 46%;
    background-repeat: no-repeat;
    background-size: 11px;
    background-image: url('https://www.infobip.com/docs/assets/img/devweb/external-link-orange.svg');
  }

  > div:first-of-type {
    position: relative;

    &::after {
      content: 'Copied to clipboard';
      position: absolute;
      z-index: 10;
      padding: 0;
      background-color: #fff;
      color: ${props => props.theme.colors.primary.main};
      font-size: 16px;
      font-weight: 700;
      line-height: 1.4;
      opacity: 0;
      will-change: left;
      transition: opacity 150ms ease-in, left 150ms ease-in;
      pointer-events: none;
    }
  }

  &.copied {
    > div:first-of-type::after,
    div.box-info::after {
      opacity: 1;
    }
  }

  &.copied {
    [data-input-border] {
      border-color: ${props => props.theme.colors.primary.main} !important;
    }

    .box-tools::after {
      opacity: 1;
    }

    i[data-target='apiKey'] svg,
    i[data-target='baseUrl'] svg {
      fill: ${props => props.theme.colors.primary.main};
    }
  }

  @media (min-width: 950px) {
    > div:first-of-type {
      position: relative;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::after {
        z-index: 10;
        top: 1px;
        bottom: 1px;
        left: 78px;
        width: calc(100% - 25px);
      }
    }

    div.box-info {
      position: relative;

      &::after {
        top: 1px;
        left: 66px;
        width: calc(100% - 75px);
        will-change: left;
        transition: opacity 150ms ease-in, left 150ms ease-in;
      }
    }

    &.shown.copied {
      div.box-info::after {
        opacity: 1;
        left: 70px;
      }
    }
  }

  @media (max-width: 949px) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: normal;

    > div:first-of-type::after {
      top: 35px;
      left: 6px;
      width: calc(100% - 10px);
    }

    div.box-info::after {
      top: 35px;
      left: 6px;
      width: calc(100% - 10px);
    }

    .box-info {
      flex-direction: column;
      width: 100%;

      strong {
        margin-bottom: 7px;
        min-width: 100%;
      }

      span[data-api-key='hidden'] {
        height: 30px;
        padding: 3px 7px;
        border: 1px solid lightgray;
        border-radius: 3px;
        font-size: 14px;
      }

      input#apiKey {
        height: 30px;
        border-radius: 3px;
        font-size: 14px;
      }
    }

    .box-tools {
      position: relative;
      width: 100%;

      &::after {
        top: -26px;
        left: 6px;
        width: calc(100% - 10px);
      }

      i[data-target='apiKey-hidden'],
      i[data-target='apiKey'],
      i[data-target='baseUrl'] {
        position: absolute;
        top: -54px;
        background-color: #fff;
        padding: 5px 7px;
      }

      i[data-target='apiKey-hidden'] {
        right: 33px;
      }

      i[data-target='apiKey'],
      i[data-target='baseUrl'] {
        right: -4px;
      }

      i[data-target='baseUrl'],
      i[data-target='apiKey-hidden'] {
        padding: 5px 7px 5px 10px;
      }
    }

    .link::before {
      content: none;
    }

    .link {
      width: auto;
      padding-left: 0;
      margin-top: 20px;
      margin-left: 0;
    }

    #boxBaseUrl & {
      > div:first-of-type {
        width: 100%;

        strong {
          display: block;
          margin-bottom: 7px;
        }

        span {
          display: block;
          height: 30px;
          padding: 2px 7px;
          border: 1px solid lightgray;
          border-radius: 3px;
          font-size: 14px;
        }
      }
    }
  }
`;
export const AlertMinor = styled.div`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #35a246;
  border-radius: 0.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
  background-color: #d4edda;

  h5 {
    display: flex;
    margin: 0;
    color: #35a246;
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;

    i {
      display: inline-block;
      margin-right: 5px;
      line-height: 0;
    }
  }
`;
export const AlertMajor = styled.div`
  position: relative;
  padding: 1.5rem;
  border: 1px solid ${props => props.theme.colors.primary.main};
  border-radius: 0.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333333;
  background-color: rgba(255, 90, 0, 0.15);

  h5 {
    display: flex;
    margin: 0;
    color: ${props => props.theme.colors.primary.main};
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;

    i {
      display: inline-block;
      margin-right: 5px;
      line-height: 0;
    }
  }
`;
export const Img = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: 40px auto;
`;
export const CodeSnippet = styled.div`
  background-color: #2d2d2d;
  margin: 30px 0;

  code {
    text-shadow: none !important;
  }
`;
export const UserLoggedBox = styled.div`
  position: relative;
  padding: 25px 30px;
  border: 1px solid rgba(66, 66, 66, 0.2);
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 90px;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);

  &#userAnonymousBox {
    p {
      margin-top: 5px;
      margin-bottom: 0;

      + p {
        margin-top: 5px;
      }
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 17px;
  }

  p strong {
    color: #333333;
    font-weight: 900;
  }

  hr {
    height: 1px;
    border: none;
    margin: 15px 0;
    background-color: #979797;
    opacity: 0.2;
  }

  @media (max-width: 349px) {
    padding: 25px 20px;
  }
`;
export const StyledUlArrows = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;

  li {
    position: relative;
    padding-left: 30px;
    color: #7f7f7f;
    font-size: 16px;
    line-height: 26px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      color: ${props => props.theme.colors.primary.main};
      background-image: url(https://cf-cdn.infobip.com/assets/2020/img/mktc/arrow-icon.svg);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 18px 18px;
    }
  }
`;
export const BtnPrimary = styled.a`
  display: inline-block;
  padding: 8px 14px;
  border-radius: 4px;
  margin-top: 31px;
  background: ${props => props.theme.colors.primary.main};
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: none;

  &:hover {
    background-color: #f75903;
    box-shadow: 2px 2px 16px 0 #f4c9b9;
  }

  + a {
    margin-left: 10px;
  }
`;
export const BtnBlank = styled.a`
  display: inline-block;
  padding: 8px 14px;
  border-radius: 4px;
  margin-top: 31px;
  background: #ffffff;
  color: #191919;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
export const BlackBox = styled.div`
  overflow: auto;
  border: 1px solid #dfe1e5;
  border-radius: 3px;
  margin: 30px 0;

  div.header {
    position: relative;
    overflow: hidden;
    padding: 5px 15px;
    border-bottom: 1px solid #ccc;
    background-color: #f5f5f5;
  }

  div.content {
    overflow: hidden;
    margin: 0;
    background-color: #ffffff;
    color: #333;
    font-size: 14px;
    line-height: 20px;

    div.syntaxhighlight {
      position: relative;
      overflow: auto;
      width: 100%;
      background-color: #1b2426;
      font-size: 1em;

      table,
      tbody,
      tr,
      td {
        background: none;
        border: 0;
        bottom: auto;
        float: none;
        height: auto;
        left: auto;
        margin: 0;
        outline: 0;
        overflow: visible;
        padding: 0;
        position: static;
        right: auto;
        text-align: left;
        top: auto;
        vertical-align: baseline;
        width: auto;
        box-sizing: content-box;
        font-family: 'Consolas', 'Bitstream Vera Sans Mono', 'Courier New', Courier, monospace;
        font-weight: normal;
        font-style: normal;
        min-height: inherit;
        line-height: 20px;
        font-size: 14px;

        .container {
          padding-bottom: 15px;
          padding-left: 0em;
          margin-top: 15px;
          font-size: 14px;
          line-height: 20px;
          white-space: pre-wrap;

          .line {
            padding: 0 1em;
            background-color: #1b2426 !important;
            white-space: nowrap;

            .js,
            .java,
            .json {
              font-size: 14px;
              line-height: 20px;
            }

            .java.color1 {
              color: #e0e8ff !important;
            }

            .java.plain {
              color: #b9bdb6 !important;
            }

            .java.string {
              color: #5ce638 !important;
            }

            .java.keyword {
              color: #5ba1cf !important;
            }

            .js.plain {
              color: #b9bdb6 !important;
            }

            .js.comments {
              color: #878a85 !important;
            }

            .js.keyword {
              color: #5ba1cf !important;
            }

            .json.plain {
              color: #b9bdb6 !important;
            }

            .json.variable {
              color: #ffaa3e !important;
            }

            .json.constants {
              color: #e0e8ff !important;
            }

            .json.string {
              color: #5ce638 !important;
            }

            .json.value {
              color: #009900 !important;
            }
          }
        }
      }
    }
  }
`;
export const StyledLandingPageHeader = styled.h3`
  margin-top: 40px;
  margin-bottom: 10px;
`;