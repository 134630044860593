import styled from '../../styled-components';
export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 21px 41px;
  gap: 10px;
`;
export const BannerText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;