import { linksCss } from '../Markdown/styled.elements';
import styled from 'styled-components';
import { Row } from '../../common-elements';
import { ServerRelativeURL } from '../Endpoint/styled.elements';
export const OperationBoxLoginInfo = styled.span.withConfig({
  componentId: "sc-1a3cvds-0"
})(["margin-bottom:0.5em;font-style:italic;", ";"], linksCss);
export const CopyClipboardButton = styled.div.withConfig({
  componentId: "sc-1a3cvds-1"
})(["cursor:pointer;display:flex;margin-left:1em;svg path{transition:0.3s;}&:hover{svg path{fill:{$props => props.theme.colors.primary.main};}}"]);
export const ToolTipContainer = styled.div.withConfig({
  componentId: "sc-1a3cvds-2"
})(["width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:flex;"]);
export const CopiedIcon = styled.div.withConfig({
  componentId: "sc-1a3cvds-3"
})(["display:flex;padding:6px 0 6px 0;"]);
export const CopiedText = styled(ServerRelativeURL).withConfig({
  componentId: "sc-1a3cvds-4"
})(["color:", ";opacity:1;font-weight:550;flex:1;margin-left:1rem;"], props => props.theme.colors.primary.main);
export const OperationRow = styled(Row).withConfig({
  componentId: "sc-1a3cvds-5"
})(["backface-visibility:hidden;contain:content;overflow:hidden;"]);
export const Description = styled.div.withConfig({
  componentId: "sc-1a3cvds-6"
})([""]);