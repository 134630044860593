import * as React from 'react';
import styled from '../../styled-components';
import { __ } from 'ib-i18n';
import { Spinner } from './Spinner.svg';
const LoadingWrapper = styled.div`
  text-align: center;
  background: #f4f5fc;
  padding-top: 80px;
  position: relative;
  height: 200px;
`;
const LoadingMessage = styled.div<{
  color: string;
}>`
  font-family: Inter, sans-serif;
  width: 100%;
  text-align: center;
  font-size: 25px;
  margin: 30px 0 20px 0;
  color: ${props => props.color};
`;
export interface LoadingProps {
  color: string;
}
export class Loading extends React.PureComponent<LoadingProps> {
  render() {
    return <LoadingWrapper>
        <LoadingMessage color={this.props.color}>{`${__('Loading')} ...`}</LoadingMessage>
        <Spinner color={this.props.color} />
      </LoadingWrapper>;
  }
}