import defaultTheme, { CUSTOM_THEMES, ResolvedThemeInterface, resolveTheme, ThemeInterface } from '../theme';
import { querySelector } from '../utils/dom';
import { isNumeric, mergeObjects } from '../utils/helpers';
import { LabelsConfigRaw, setRedocLabels } from './Labels';
import { MDXComponentMeta } from './MarkdownRenderer';
export interface RedocRawOptions {
  theme?: ThemeInterface;
  scrollYOffset?: number | string | (() => number);
  hideHostname?: boolean | string;
  expandResponses?: string | 'all';
  requiredPropsFirst?: boolean | string;
  sortPropsAlphabetically?: boolean | string;
  noAutoAuth?: boolean | string;
  nativeScrollbars?: boolean | string;
  pathInMiddlePanel?: boolean | string;
  untrustedSpec?: boolean | string;
  hideLoading?: boolean | string;
  hideDownloadButton?: boolean | string;
  disableSearch?: boolean | string;
  onlyRequiredInSamples?: boolean | string;
  showExtensions?: boolean | string | string[];
  hideSingleRequestSampleTab?: boolean | string;
  menuToggle?: boolean | string;
  jsonSampleExpandLevel?: number | string | 'all';
  hideSchemaTitles?: boolean | string;
  disableInfiniteScroll?: boolean | string;
  navBarEnabled?: boolean | string;
  navBarScrollEnabled?: boolean | string;
  footerEnabled?: boolean | string;
  loginUrl?: string;
  signupUrl?: string;
  showGeneratedAt?: string;
  apiUrl?: string;
  surveyMonkeyEnabled?: boolean;
  surveyMonkeyId?: string;
  expandSingleSchemaField?: boolean | string;
  baseUrl?: string;
  docsUrl?: string;
  docsUrlEncoded?: string;
  portalUrl?: string;
  environment?: string;
  unstable_ignoreMimeParameters?: boolean;
  allowedMdComponents?: Dict<MDXComponentMeta>;
  labels?: LabelsConfigRaw;
  enumSkipQuotes?: boolean | string;
  expandDefaultServerVariables?: boolean;
  themeName?: string;
}
function argValueToBoolean(val?: string | boolean): boolean {
  if (val === 'false') {
    return false;
  }
  return Boolean(val);
}
export class RedocNormalizedOptions {
  static normalizeExpandResponses(value: RedocRawOptions['expandResponses']) {
    if (value === 'all') {
      return 'all';
    }
    if (typeof value === 'string') {
      const res = {};
      value.split(',').forEach(code => {
        res[code.trim()] = true;
      });
      return res;
    } else if (value !== undefined) {
      console.warn(`expandResponses must be a string but received value "${value}" of type ${typeof value}`);
    }
    return {};
  }
  static normalizeHideHostname(value: RedocRawOptions['hideHostname']): boolean {
    return !!value;
  }
  static normalizeScrollYOffset(value: RedocRawOptions['scrollYOffset']): () => number {
    // just number is not valid selector and leads to crash so checking if isNumeric here
    if (typeof value === 'string' && !isNumeric(value)) {
      const el = querySelector(value);
      if (!el) {
        console.warn('scrollYOffset value is a selector to non-existing element. Using offset 0 by default');
      }
      const bottom = el && el.getBoundingClientRect().bottom || 0;
      return () => bottom;
    } else if (typeof value === 'number' || isNumeric(value)) {
      return () => typeof value === 'number' ? value : parseFloat(value);
    } else if (typeof value === 'function') {
      return () => {
        const res = value();
        if (typeof res !== 'number') {
          console.warn(`scrollYOffset should return number but returned value "${res}" of type ${typeof res}`);
        }
        return res;
      };
    } else if (value !== undefined) {
      console.warn('Wrong value for scrollYOffset ReDoc option: should be string, number or function');
    }
    return () => 0;
  }
  static normalizeShowExtensions(value: RedocRawOptions['showExtensions']): string[] | boolean {
    if (typeof value === 'undefined') {
      return false;
    }
    if (value === '') {
      return true;
    }
    if (typeof value === 'string') {
      return value.split(',').map(ext => ext.trim());
    }
    return value;
  }
  private static normalizeJsonSampleExpandLevel(level?: number | string | 'all'): number {
    if (level === 'all') {
      return +Infinity;
    }
    if (!isNaN(Number(level))) {
      return Math.ceil(Number(level));
    }
    return 2;
  }
  theme: ResolvedThemeInterface;
  scrollYOffset: () => number;
  hideHostname: boolean;
  expandResponses: {
    [code: string]: boolean;
  } | 'all';
  requiredPropsFirst: boolean;
  sortPropsAlphabetically: boolean;
  noAutoAuth: boolean;
  nativeScrollbars: boolean;
  pathInMiddlePanel: boolean;
  untrustedSpec: boolean;
  hideDownloadButton: boolean;
  disableSearch: boolean;
  onlyRequiredInSamples: boolean;
  showExtensions: boolean | string[];
  hideSingleRequestSampleTab: boolean;
  menuToggle: boolean;
  jsonSampleExpandLevel: number;
  enumSkipQuotes: boolean;
  hideSchemaTitles: boolean;
  disableInfiniteScroll: boolean;
  navBarEnabled: boolean;
  navBarScrollEnabled: boolean;
  footerEnabled: boolean;
  loginUrl: string;
  logoUrl: string;
  signupUrl: string;
  showGeneratedAt: boolean;
  apiUrl: string;
  surveyMonkeyEnabled: boolean;
  surveyMonkeyId: string;
  expandSingleSchemaField: boolean;
  baseUrl: string;
  docsUrl: string;
  docsUrlEncoded: string;
  portalUrl: string;
  environment: string;

  /* tslint:disable-next-line */
  unstable_ignoreMimeParameters: boolean;
  allowedMdComponents: Dict<MDXComponentMeta>;
  expandDefaultServerVariables: boolean;
  constructor(raw: RedocRawOptions, defaults: RedocRawOptions = {}) {
    raw = {
      ...defaults,
      ...raw
    };
    const hook = raw.theme && raw.theme.extensionsHook;
    const theme = raw.themeName ? {
      ...CUSTOM_THEMES[raw.themeName]
    } : raw.theme;
    this.theme = resolveTheme(mergeObjects(({} as any), defaultTheme, {
      ...theme,
      extensionsHook: undefined
    }));
    this.theme.extensionsHook = (hook as any);

    // do not support dynamic labels changes. Labels should be configured before
    setRedocLabels(raw.labels);
    this.scrollYOffset = RedocNormalizedOptions.normalizeScrollYOffset(raw.scrollYOffset);
    this.hideHostname = RedocNormalizedOptions.normalizeHideHostname(raw.hideHostname);
    this.expandResponses = RedocNormalizedOptions.normalizeExpandResponses(raw.expandResponses);
    this.requiredPropsFirst = argValueToBoolean(raw.requiredPropsFirst);
    this.sortPropsAlphabetically = argValueToBoolean(raw.sortPropsAlphabetically);
    this.noAutoAuth = argValueToBoolean(raw.noAutoAuth);
    this.nativeScrollbars = argValueToBoolean(raw.nativeScrollbars);
    this.pathInMiddlePanel = argValueToBoolean(raw.pathInMiddlePanel);
    this.untrustedSpec = argValueToBoolean(raw.untrustedSpec);
    this.hideDownloadButton = argValueToBoolean(raw.hideDownloadButton);
    this.disableSearch = argValueToBoolean(raw.disableSearch);
    this.onlyRequiredInSamples = argValueToBoolean(raw.onlyRequiredInSamples);
    this.showExtensions = RedocNormalizedOptions.normalizeShowExtensions(raw.showExtensions);
    this.hideSingleRequestSampleTab = argValueToBoolean(raw.hideSingleRequestSampleTab);
    this.menuToggle = argValueToBoolean(raw.menuToggle);
    this.jsonSampleExpandLevel = RedocNormalizedOptions.normalizeJsonSampleExpandLevel(raw.jsonSampleExpandLevel);
    this.enumSkipQuotes = argValueToBoolean(raw.enumSkipQuotes);
    this.hideSchemaTitles = argValueToBoolean(raw.hideSchemaTitles);
    this.disableInfiniteScroll = argValueToBoolean(raw.disableInfiniteScroll);
    this.unstable_ignoreMimeParameters = argValueToBoolean(raw.unstable_ignoreMimeParameters);
    this.navBarEnabled = argValueToBoolean(raw.navBarEnabled);
    this.navBarScrollEnabled = argValueToBoolean(raw.navBarScrollEnabled);
    this.footerEnabled = argValueToBoolean(raw.footerEnabled);
    this.loginUrl = raw.loginUrl || 'https://portal.infobip.com/login/?callback=';
    this.signupUrl = raw.signupUrl || 'https://www.infobip.com/signup';
    this.showGeneratedAt = raw.showGeneratedAt === 'true';
    this.apiUrl = raw.apiUrl || 'https://api.infobip.com';
    this.surveyMonkeyEnabled = argValueToBoolean(raw.surveyMonkeyEnabled);
    this.surveyMonkeyId = raw.surveyMonkeyId || '';
    this.baseUrl = raw.baseUrl || '';
    this.docsUrl = raw.docsUrl || 'https://www.infobip.com/docs';
    this.docsUrlEncoded = raw.docsUrlEncoded || 'https%3A%2F%2Fwww.infobip.com%2Fdocs';
    this.portalUrl = raw.portalUrl || 'https://portal.infobip.com';
    this.environment = raw.environment || '';
    this.expandSingleSchemaField = argValueToBoolean(raw.expandSingleSchemaField);
    this.allowedMdComponents = raw.allowedMdComponents || {};
    this.expandDefaultServerVariables = argValueToBoolean(raw.expandDefaultServerVariables);
  }
}