import { observer } from 'mobx-react';
import { __ } from 'ib-i18n';
import * as React from 'react';
import { Container, FooterWrapper, Grid, GridItemLeftSegment, GridItemRightSegment, LinkContainer, LinksWrapper, LogoSvg, NestedContainer, PrimaryLinkButton, ResearchPanelHeading, ResearchPanelText, StyledBox, ResponsiveFlex, StyledLink, TextComponent, SocialIconsWrapper, Badge, StyledLinkUnderline, RowFlex } from './styled.elements';
import logo from '../../images/infobip-logo-large.svg';
import helpIcon from '../../images/help-icon.svg';
import headphonesIcon from '../../images/headphones-icon.svg';
import documentIcon from '../../images/document-icon.svg';
import aLetterIcon from '../../images/a-letter-icon.svg';
import greenStatusIcon from '../../images/green-status-icon.svg';
import xLogoIcon from '../../images/x-logo.svg';
import linkedinLogoIcon from '../../images/linkedin-logo.svg';
import facebookLogoIcon from '../../images/facebook-logo.svg';
import githubLogoIcon from '../../images/github-logo.svg';
import discordLogoIcon from '../../images/discord-logo.svg';
interface FooterProps {
  docsUrl: string;
  logoUrl?: string;
}
@observer
export class Footer extends React.Component {
  props: FooterProps;
  render() {
    return <FooterWrapper>
        {/* Top Part */}
        <Container>
          <Grid>
            {/* Left Segment */}
            <GridItemLeftSegment>
              <LogoSvg src={this.props.logoUrl || logo} title="Infobip Logo" />
              {/* Left-aligned children */}
              <LinksWrapper>
                <LinkContainer>
                  <NestedContainer>
                    <LogoSvg src={helpIcon} />
                    <TextComponent>{__('Need assistance')}</TextComponent>
                  </NestedContainer>
                  <StyledLinkUnderline href={`${this.props.docsUrl}/tutorials`}>
                    {__('Explore Infobip Tutorials')}
                  </StyledLinkUnderline>
                </LinkContainer>
                <LinkContainer>
                  <NestedContainer>
                    <LogoSvg src={headphonesIcon} />
                    <TextComponent>{__('Encountering issues')}</TextComponent>
                  </NestedContainer>
                  <StyledLinkUnderline href="https://www.infobip.com/contact">
                    {__('Contact our support')}
                  </StyledLinkUnderline>
                </LinkContainer>
                <LinkContainer>
                  <NestedContainer>
                    <LogoSvg src={documentIcon} />
                    <TextComponent>
                      {/* prettier-ignore */
                    __('What\'s new? Check out')}
                    </TextComponent>
                  </NestedContainer>
                  <StyledLinkUnderline href={`${this.props.docsUrl}/release-notes`}>
                    {__('Release Notes')}
                  </StyledLinkUnderline>
                </LinkContainer>
                <LinkContainer>
                  <NestedContainer>
                    <LogoSvg src={aLetterIcon} />
                    <TextComponent>{__('Unsure about a term? See')}</TextComponent>
                  </NestedContainer>
                  <StyledLinkUnderline href="/glossary-hub">{__('Glossary')}</StyledLinkUnderline>
                </LinkContainer>
              </LinksWrapper>
            </GridItemLeftSegment>

            {/* Right Segment */}
            <GridItemRightSegment>
              <ResearchPanelText>{__('Research panel')}</ResearchPanelText>
              <ResearchPanelHeading as="h5">
                {__('Help shape the future of our products')}
              </ResearchPanelHeading>
              <PrimaryLinkButton href="https://www.infobip.com/research-panel">
                {__('Learn more')}
              </PrimaryLinkButton>
            </GridItemRightSegment>
          </Grid>
        </Container>

        {/* Bottom Part */}
        <Container>
          <ResponsiveFlex>
            <StyledBox>
              <ResponsiveFlex>
                <StyledLink href="https://status.infobip.com/">
                  <Badge>
                    <LogoSvg src={greenStatusIcon} />
                    {__('Service status')}
                  </Badge>
                </StyledLink>
                <TextComponent>{__('Copyright @ 2006-2022 Infobip ltd.')}</TextComponent>
              </ResponsiveFlex>
            </StyledBox>
            <StyledBox>
              <ResponsiveFlex>
                <RowFlex>
                  <StyledLink href="https://www.infobip.com/privacy-documents">
                    {__('Privacy documents')}
                  </StyledLink>
                  <StyledLink href="https://www.infobip.com/policies">
                    {__('Terms of use')}
                  </StyledLink>
                </RowFlex>
                <SocialIconsWrapper>
                  <StyledLink href="https://twitter.com/Infobip">
                    <LogoSvg src={xLogoIcon} />
                  </StyledLink>
                  <StyledLink href="https://www.linkedin.com/company/infobip">
                    <LogoSvg src={linkedinLogoIcon} />
                  </StyledLink>
                  <StyledLink href="https://www.facebook.com/infobip/">
                    <LogoSvg src={facebookLogoIcon} />
                  </StyledLink>
                  <StyledLink href="https://github.com/infobip">
                    <LogoSvg src={githubLogoIcon} />
                  </StyledLink>
                  <StyledLink href="https://discord.com/invite/G9Gr6fk2e4">
                    <LogoSvg src={discordLogoIcon} />
                  </StyledLink>
                </SocialIconsWrapper>
              </ResponsiveFlex>
            </StyledBox>
          </ResponsiveFlex>
        </Container>
      </FooterWrapper>;
  }
}