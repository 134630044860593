import { __ } from 'ib-i18n';
import * as React from 'react';
import { ContentItemModel } from '../../services';
import { Link } from '../../common-elements';
import { BreadcrumbsLi, BreadcrumbsUl, StyledDiv } from './styled.elements';
export class Breadcrumbs extends React.Component<{
  item: ContentItemModel;
}> {
  private static resolveBreadcrumbsComponents(item: ContentItemModel): ContentItemModel[] {
    const hierarchy: ContentItemModel[] = [];
    let currentParent = item.parent;
    while (currentParent) {
      if (currentParent.type !== 'section') {
        hierarchy.push(currentParent);
      }
      currentParent = currentParent.parent;
    }
    return hierarchy.reverse();
  }
  private static breadcrumbsItem(to: string, name: string) {
    return <BreadcrumbsLi className="breadcrumbs-item" key={to}>
        <Link to={to}>{name}</Link>
      </BreadcrumbsLi>;
  }
  render() {
    const breadcrumbsComponents = [Breadcrumbs.breadcrumbsItem('', __('Get Started'))];
    Breadcrumbs.resolveBreadcrumbsComponents(this.props.item).forEach(component => breadcrumbsComponents.push(Breadcrumbs.breadcrumbsItem(component.id, component.name)));
    return <StyledDiv>
        <BreadcrumbsUl>{breadcrumbsComponents}</BreadcrumbsUl>
      </StyledDiv>;
  }
}