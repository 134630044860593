// tslint:disable-next-line:no-implicit-dependencies
import { toArray } from 'lodash';
import * as React from 'react';
import { AppStore } from '../..';
import { ReqResSchemaHeader, ReqResSchemaTypeChooser } from '../../common-elements';
import { FieldModel, MediaContentModel, RequestBodyModel } from '../../services/models';
import { DropdownOrLabel } from '../DropdownOrLabel/DropdownOrLabel';
import { ExpandCollapse } from '../ExpandCollapse/ExpandCollapse';
import { Markdown } from '../Markdown/Markdown';
import { MediaTypesSwitch } from '../MediaTypeSwitch/MediaTypesSwitch';
import { Schema } from '../Schema';
import { StoreConsumer } from '../StoreBuilder';
import { ParametersGroup } from './ParametersGroup';
import { AuthHeader } from '../SecurityRequirement/SecurityRequirement';
function safePush(obj, prop, item) {
  if (!obj[prop]) {
    obj[prop] = [];
  }
  obj[prop].push(item);
}
export interface ParametersProps {
  parameters?: FieldModel[];
  body?: RequestBodyModel;
}
const PARAM_PLACES = ['path', 'query', 'cookie', 'header'];
export class Parameters extends React.PureComponent<ParametersProps> {
  orderParams(params: FieldModel[]): Dict<FieldModel[]> {
    const res = {};
    params.forEach(param => {
      safePush(res, param.in, param);
    });
    return res;
  }
  render() {
    const {
      body,
      parameters = []
    } = this.props;
    if (body === undefined && parameters === undefined) {
      return null;
    }
    const paramsMap = this.orderParams(parameters);
    const paramsPlaces = parameters.length > 0 ? PARAM_PLACES : [];
    const bodyContent = body && body.content;
    const bodyDescription = body && body.description;
    return <>
        {paramsPlaces.map(place => <ParametersGroup key={place} place={place} parameters={paramsMap[place]} />)}
        {bodyContent && <BodyContent content={bodyContent} description={bodyDescription} />}
      </>;
  }
}
function DropdownWithinHeader(props, content: MediaContentModel) {
  const checkIfContentIsExpanded = (): boolean => {
    const examples = toArray(content.mediaTypes[content.mediaTypes.findIndex(obj => obj.name === props.value.label)].examples);
    let isExpanded = true;
    examples.forEach(example => {
      if (!example.expanded) {
        isExpanded = false;
      }
    });
    return isExpanded;
  };
  return <ReqResSchemaHeader key="header">
      <ReqResSchemaTypeChooser>
        <AuthHeader>Request Body schema:</AuthHeader>
        <DropdownOrLabel {...props} />
      </ReqResSchemaTypeChooser>
      <ExpandCollapse onCollapse={props.onCollapse} onExpand={props.onExpand} expanded={checkIfContentIsExpanded()} />
    </ReqResSchemaHeader>;
}
function collapse(store: AppStore) {
  store?.collapseFieldsAndSamples();
}
function expand(store?: AppStore) {
  store?.expandFieldsAndSamples();
}
function BodyContent(props: {
  content: MediaContentModel;
  description?: string;
}): JSX.Element {
  const {
    content,
    description
  } = props;
  return <StoreConsumer>
      {store => <MediaTypesSwitch content={content} onExpand={expand.bind(this, store)} onCollapse={collapse.bind(this, store)}
    // tslint:disable-next-line
    renderDropdown={props => DropdownWithinHeader(props, content)}>
          {({
        schema
      }) => {
        return <>
                {description !== undefined && <Markdown source={description} />}
                <Schema skipReadOnly={true} key="schema" schema={schema} />
              </>;
      }}
        </MediaTypesSwitch>}
    </StoreConsumer>;
}