import styled from 'styled-components';
import { linksCss } from '../Markdown/styled.elements';
export interface FooterWrapperProps {
  gap?: string;
}
export const FooterWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-0"
})(["display:flex;flex-direction:column;gap:", ";padding-inline-start:80px;padding-inline-end:80px;padding-top:56px;padding-bottom:56px;color:#424b5c;background:#f8f9fd;z-index:2;position:relative;left:0;right:0;width:100%;margin-left:0;"], props => props.gap || '96px');
export const Container = styled.div.withConfig({
  componentId: "sc-ygrp15-1"
})(["width:100%;margin-inline-start:auto;margin-inline-end:auto;max-width:100%;padding:0;"]);
export const Grid = styled.div.withConfig({
  componentId: "sc-ygrp15-2"
})(["display:grid;grid-gap:96px;grid-template-columns:repeat(1,1fr);@media screen and (min-width:62em){grid-template-columns:repeat(6,1fr);}"]);
export const GridItemLeftSegment = styled.div.withConfig({
  componentId: "sc-ygrp15-3"
})(["grid-column:span 1 / span 1;@media screen and (min-width:62em){grid-column:span 4 / span 4;}"]);
export const GridItemRightSegment = styled.div.withConfig({
  componentId: "sc-ygrp15-4"
})(["grid-column-start:1;grid-column-end:2;grid-row-start:2;grid-row-end:3;justify-content:center;flex-direction:column;gap:8px;display:flex;padding-inline-start:24px;border-inline-start-width:1px;border-inline-start-style:solid;border-inline-start-color:#d0dcf2;@media screen and (min-width:62em){grid-column-start:5;grid-column-end:7;grid-row-start:1;grid-row-end:2;}"]);
export const LinksWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-5"
})(["display:flex;gap:24px;margin-top:32px;flex-direction:column;"]);
export const LinkContainer = styled.div.withConfig({
  componentId: "sc-ygrp15-6"
})(["display:flex;flex-direction:row;gap:8px;"]);
export const NestedContainer = styled.div.withConfig({
  componentId: "sc-ygrp15-7"
})(["display:flex;flex-direction:row;gap:8px;"]);
export const TextComponent = styled.p.withConfig({
  componentId: "sc-ygrp15-8"
})(["margin:0;align-self:start;@media (min-width:62em){align-self:center;}"]);
export const StyledLinkUnderline = styled.a.withConfig({
  componentId: "sc-ygrp15-9"
})(["color:#2176ae;font-weight:600;text-decoration:underline;"]);
export const StyledLink = styled.a.withConfig({
  componentId: "sc-ygrp15-10"
})(["color:#2176ae;font-weight:600;text-decoration:none;height:fit-content;"]);
export const LogoSvg = styled.img.withConfig({
  componentId: "sc-ygrp15-11"
})(["height:fit-content;display:block;align-self:center;max-height:3.604rem;"]);
export const ResearchPanelText = styled.p.withConfig({
  componentId: "sc-ygrp15-12"
})(["color:#677796;font-size:1rem;text-transform:uppercase;margin:0;"]);
export const ResearchPanelHeading = styled.h5.withConfig({
  componentId: "sc-ygrp15-13"
})(["font-size:1.5rem;margin:0;"]);
export const PrimaryLinkButton = styled.a.withConfig({
  componentId: "sc-ygrp15-14"
})(["display:inline-flex;align-items:center;justify-content:center;appearance:none;user-select:none;position:relative;white-space:nowrap;vertical-align:middle;outline:2px solid transparent;outline-offset:2px;line-height:1.2;border-radius:0.375rem;transition-property:common;transition-duration:0.15s;height:2.5rem;min-width:2.5rem;font-size:1rem;font-weight:600;padding-inline-start:1rem;padding-inline-end:1rem;background:#fc6423;color:#ffffff;width:fit-content;margin-top:24px;text-decoration:none;"]);
export const StyledP = styled.p.withConfig({
  componentId: "sc-ygrp15-15"
})(["", ";"], linksCss);
export const StyledDiv = styled.div.withConfig({
  componentId: "sc-ygrp15-16"
})(["", ""], linksCss);
export const ResponsiveFlex = styled.div.withConfig({
  componentId: "sc-ygrp15-17"
})(["display:flex;width:100%;margin:auto;flex-direction:column;gap:1rem;justify-content:space-between;@media (min-width:62em){flex-direction:row;}"]);
export const RowFlex = styled.div.withConfig({
  componentId: "sc-ygrp15-18"
})(["display:flex;width:100%;margin:auto;flex-direction:row;gap:1rem;"]);
export const StyledBox = styled.div.withConfig({
  componentId: "sc-ygrp15-19"
})(["max-width:100%;padding:0;"]);
export const SocialIconsWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-20"
})(["display:flex;flex-direction:row;gap:16px;padding-inline-start:16px;border-inline-start-width:1px;border-inline-start-style:solid;border-inline-start-color:#d0dcf2;"]);
export const Badge = styled.span.withConfig({
  componentId: "sc-ygrp15-21"
})(["white-space:nowrap;vertical-align:middle;padding-inline-start:8px;padding-inline-end:8px;text-transform:none;font-size:0.875rem;border-radius:16px;font-weight:600;background:#f8f9fa;color:#6c757d;box-shadow:inset 0 0 0 1px #6c757d;padding:4px 8px;display:flex;direction:ltr;gap:6px;width:fit-content;"]);