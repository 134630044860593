import * as React from 'react';
import { OpenAPIXDeprecationInformation } from '../../types';
import { format } from 'date-fns';
import { DeprecationInformationBoxStyle, DeprecationInformationColumnStyle, DeprecationText, DeprecationInformationMainTextStyle } from './styled.elements';
import { IMenuItem, OperationModel } from '../../services';
import { Link } from '../../common-elements';
interface DeprecationInformationTextProps {
  text: string;
}
const DeprecationInformationText = ({
  text
}: DeprecationInformationTextProps): JSX.Element => {
  return <DeprecationInformationMainTextStyle>{text}</DeprecationInformationMainTextStyle>;
};
export interface Props {
  xDeprecationInfo: OpenAPIXDeprecationInformation;
  getItemByOperationId: (operationId: string) => IMenuItem | undefined;
}
export function DeprecationInformationBox({
  xDeprecationInfo,
  getItemByOperationId
}: Props): JSX.Element {
  const [successorOperationModel, setSuccessorOperationModel] = React.useState<OperationModel | undefined>();
  const formatDate = (dateString: string | undefined): string | undefined => {
    if (!!dateString) {
      try {
        dateString = format(new Date(dateString), 'MMM d, yyyy');
      } catch (e) {
        console.warn('Cannot format date: ', e);
      }
    }
    return dateString;
  };
  const findSuccessorOperation = (successorOperationId: string | undefined) => {
    if (!successorOperationId) {
      setSuccessorOperationModel(undefined);
      return;
    }
    let childItem = (getItemByOperationId(successorOperationId) as OperationModel);
    while (!!successorOperationId && !!childItem.xDeprecationInformation?.successorOperationId) {
      childItem = (getItemByOperationId(successorOperationId) as OperationModel);
      successorOperationId = childItem.xDeprecationInformation?.successorOperationId;
    }
    if (xDeprecationInfo?.successorOperationId) {
      setSuccessorOperationModel(childItem ?? undefined);
    }
  };
  const findSummaryNameForSuccessorOperation = () => {
    return !!successorOperationModel ? successorOperationModel.name : undefined;
  };
  const findPathForSuccessorOperation = (): string => {
    return !!successorOperationModel ? successorOperationModel.id : '';
  };
  React.useEffect(() => {
    findSuccessorOperation(xDeprecationInfo.successorOperationId);
  }, [xDeprecationInfo, parent]);
  return <>
      <DeprecationInformationBoxStyle>
        <DeprecationInformationColumnStyle>
          <DeprecationInformationText text="DEPRECATED:" />
          <DeprecationInformationText text="SUNSET:" />
          <DeprecationInformationText text="SUCCESSOR:" />
        </DeprecationInformationColumnStyle>
        <DeprecationInformationColumnStyle>
          <div>{formatDate(xDeprecationInfo.deprecation)}.</div>
          {formatDate(xDeprecationInfo.sunset) === undefined ? <div>Not planned</div> : <DeprecationText>{formatDate(xDeprecationInfo.sunset)}.</DeprecationText>}
          {xDeprecationInfo.successorOperationId === undefined ? <div>None</div> : <Link to={findPathForSuccessorOperation()}>
              {findSummaryNameForSuccessorOperation()}
            </Link>}
        </DeprecationInformationColumnStyle>
      </DeprecationInformationBoxStyle>
    </>;
}