import styled from '../../styled-components';
export const DeprecationInformationBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 -40px 0 -40px;
  padding: 20px 20px 20px 40px;
  gap: 40px;
  background: rgba(212, 3, 80, 0.04);
`;
export const DeprecationInformationColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  a {
    color: ${props => props.theme.typography.links.color};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.typography.links.hover} !important;
    }
  }
`;
export const DeprecationInformationMainTextStyle = styled.div`
  color: rgba(38, 50, 56, 0.5);
  font-weight: normal;
  text-transform: uppercase;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
`;
export const DeprecationText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;