import { observer } from 'mobx-react';
import * as React from 'react';
import logo from '../../images/infobip-logo.svg';
import { __ } from 'ib-i18n';
import { AppStore } from '../../services';
import { LogoContainer, LogoSvg, MainNav, NavBarLi, NavBarUl, NavWrapper, ScrollTransition, StyledLink, UnstyledLink, HamburgerMenu, MenuButton, MenuSidebar, MenuItem, PrimaryLinkButton } from './styled.elements';
interface NavBarProps {
  store: AppStore;
  scrollEnabled: boolean;
  docsUrl: string;
  logoUrl?: string;
}
interface DocsLink {
  label: string;
  url: string;
  active: boolean;
  className?: string;
  target?: '_blank' | '_self';
}
class DocsLinksProvider {
  constructor(private readonly docsUrl: string) {
    this.docsUrl = docsUrl;
  }
  docsLinks(): DocsLink[] {
    return [{
      label: __('Product Documentation'),
      url: this.buildDocsUrl('/product-documentation'),
      active: false
    }, {
      label: __('Essentials'),
      url: this.buildDocsUrl('/essentials'),
      active: false
    }, {
      label: __('Tutorials'),
      url: this.buildDocsUrl('/tutorials'),
      active: false
    }, {
      label: __('Release Notes'),
      url: this.buildDocsUrl('/release-notes'),
      active: false
    }, {
      label: __('Integrations'),
      url: this.buildDocsUrl('/integrations'),
      active: false
    }, {
      label: __('API'),
      url: window.location.origin + '/docs/api',
      active: true
    }, {
      label: __('SDKs'),
      url: this.buildDocsUrl('/sdk'),
      active: false
    }, {
      label: __('Join us at Shift \'24'),
      url: 'https://shift.infobip.com/',
      active: false,
      className: 'shift',
      target: '_blank'
    }];
  }
  private buildDocsUrl = (path: string): string => `${this.docsUrl}${path}`;
}
interface NavBarState {
  currentUrl: string;
  prevScrollPos: number;
  show: boolean;
  logoUrl?: string;
}
@observer
export class NavBar extends React.Component<NavBarProps, NavBarState> {
  private readonly docsLinksProvider: DocsLinksProvider;
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.href,
      prevScrollPos: window.scrollY,
      logoUrl: props.logoUrl || logo,
      show: true
    };
    this.docsLinksProvider = new DocsLinksProvider(this.props.docsUrl);
  }
  componentDidMount() {
    if (this.props.scrollEnabled) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount() {
    if (this.props.scrollEnabled) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const show = this.state.prevScrollPos > currentScrollPos;
    this.setState({
      prevScrollPos: currentScrollPos,
      show
    });
  };
  handleLoginHover = () => {
    this.setState({
      currentUrl: window.location.href.replace(/^http:\/\//i, 'https://')
    });
  };
  handleLogoutOnClick = () => {
    this.props.store.logoutUser();
  };
  render() {
    const {
      signupUrl
    } = this.props.store.options;
    const user = this.props.store.user;
    const loginUrl = this.props.store.options.loginUrl;
    return <ScrollTransition>
        <NavWrapper className="navbar">
          <MainNav>
            <LogoContainer href={window.location.origin}>
              <LogoSvg src={this.state.logoUrl} title="Infobip Logo" className="logo" />
            </LogoContainer>

            <NavBarUl>
              {this.docsLinksProvider.docsLinks().map((docsLink, index) => <NavBarLi key={`docs-link-${index}`} className={docsLink.active ? 'active' : docsLink.className || ''}>
                  <UnstyledLink href={docsLink.url} target={docsLink.target}>
                    {docsLink.label}
                  </UnstyledLink>
                </NavBarLi>)}
            </NavBarUl>

            {user.loggedIn ? <div className="navbar-logout">
                <StyledLink onClick={this.handleLogoutOnClick}>{user.userName}</StyledLink>
                <StyledLink onClick={this.handleLogoutOnClick}>Log out</StyledLink>
              </div> : <>
                <StyledLink onMouseEnter={this.handleLoginHover} href={loginUrl.concat(this.state.currentUrl)} className="navbar-login">
                  {__('Log in')}
                </StyledLink>
                <PrimaryLinkButton href={signupUrl}>{__('Try for free')}</PrimaryLinkButton>
              </>}
            <HamburgerMenu>
              <input id="hamburger" type="checkbox" />
              <MenuButton className="menu__btn" htmlFor="hamburger">
                <span />
              </MenuButton>

              <MenuSidebar className="menu-sidebar">
                {this.docsLinksProvider.docsLinks().map((docsLink, index) => <MenuItem key={`docs-link-${index}`}>
                    <UnstyledLink href={docsLink.url}>{docsLink.label}</UnstyledLink>
                  </MenuItem>)}
              </MenuSidebar>
            </HamburgerMenu>
          </MainNav>
        </NavWrapper>
      </ScrollTransition>;
  }
}